import React from "react";
import reactDom from "react-dom";

export default function CopyRight(){
    return(
        <div className="ct-copyrightarea">
            <div className="ct-containerfluid">
                <div className="ct-copyrightcontent">
                    <p>2024 All Rights Reserved © Consforc</p>
                </div>
                <div className="ct-termsarea">
                    <ul>
                        <li className="ct-active">
                            <a href="/">Home</a>
                        </li>
                        {/* <li>
                            <a href="javascript: void(0);">Terms & Conditions</a>
                        </li>
                        <li>
                            <a href="javascript: void(0);">Privacy Policy</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}