import React, {useEffect} from "react";
import reactDom from "react-dom";
import Header from "../header/Header";
import Banner_image from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Capabilitie_box from './capabilities_sections/Capabilitie_box';
import Tabs from 'react-bootstrap/Tabs';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Tab from 'react-bootstrap/Tab';
import "./capabilities.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// import './staff.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5  
  },
  tablet: {
    breakpoint: { max: 1024, min: 568 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 568, min: 0 },
    items: 1
  }
};

export default function Home(){
    useEffect(() => {
        AOS.init(1000);
    }, []);
    return(
        <>
            <Header/>
            {/* <Banner /> */}
            <Banner_image banner={{
                image: [("image/capabilit.png")] ,
                title: 'One Direction: FORWARD',
                description: 'Unlock the true potential of your dreams using latest technology',
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-capabilitiesholder">
                    <div className="ct-capabilitie">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-containerfluid">
                                <div className="ct-sectionhead">
                                    <div className="ct-sectiontitle">
                                        <h2>Capabilities </h2>
                                    </div>
                                    <div className="ct-description">
                                        <p>Based on your business needs, you can choose to outsource your technology projects to our expert teams.</p>
                                    </div>
                                </div>
                                <div className="ct-capabilitestabshlder">
                                    <Tabs defaultActiveKey="Financial" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="Financial" title="Financial Services">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/1.svg',
                                                        title: 'Middleware Integrations',
                                                        description: 'Integrate your middleware with multiple processors to get the maximum out of every payment processor.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/2.svg',
                                                        title: 'Card Program Implementation',
                                                        description: 'Whether you are using a single BIN or utilizing its ranges for different programs, our program managers will fulfill all that your card program requires.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/3.svg',
                                                        title: 'Payment APIs',
                                                        description: 'Go beyond the basics by creating your own payment APIs',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/4.svg',
                                                        title: 'Product Development',
                                                        description: 'From cardholder portals to dispute management systems to payment processing engines, we will cover it all for you - share your use cases to find out more about how we can help you. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/5.svg',
                                                        title: 'Digital Wallets ',
                                                        description: 'Optimize your products with In app provisioning and providing your customers digital wallets to make smarter payments.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/6.svg',
                                                        title: 'Banking Mobile Apps',
                                                        description: 'Bring banking to your fingertips by consuming our white label solutions - completely customizable to meet all your banking needs. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/7.svg',
                                                        title: 'Client support ',
                                                        description: 'Ensure timely resolution of technical queries raised by your customers through voice & non-voice channels. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/8.svg',
                                                        title: 'Dispute & Chargebacks ',
                                                        description: 'Never miss filing a single dispute again. Our experts monitor your programs closely to ensure that all disputes are timely handled.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/fs/9.svg',
                                                        title: 'Settlement & Reconciliation ',
                                                        description: 'No need to manually settle your reports when your funds are settled electronically -- automate your reports for maximum accuracy. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Application" title="Application Development">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/ad/3.svg',
                                                        title: 'iOS',
                                                        description: 'Build robust apps ground up using the latest tools and technologies.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/ad/1.svg',
                                                        title: 'Android',
                                                        description: 'Achieve more app stability and performance with core Android apps.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/ad/2.svg',
                                                        title: 'Flutter',
                                                        description: 'Dark might just be the future. With flutter, think, build, and use your apps -- it is that easy.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/ad/4.svg',
                                                        title: 'React Native ',
                                                        description: 'Get more control with native apps especially with your unique use cases.',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Project" title="Project Management">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/pm/1.svg',
                                                        title: 'Project Portfolio Management ',
                                                        description: 'Whether you are using a single BIN or utilizing its ranges for different programs, our program managers will fulfill all that your card program requires.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/pm/2.svg',
                                                        title: 'Project Methodology Implementation',
                                                        description: 'Utilize the expertise of project and portfolio managers to manage your projects’ planning, estimations, schedules, and project deliverable needs. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/pm/3.svg',
                                                        title: 'Vendor Management ',
                                                        description: 'Engage technical staff to manage 3rd party vendors on your behalf. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/pm/4.svg',
                                                        title: 'Resource Management',
                                                        description: 'Manage virtual project teams across the globe while substantially reducing overheads.',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Operations" title="Back-Office Operations">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/1.svg',
                                                        title: 'Data entry and processing',
                                                        // description: 'Integrate your middleware with multiple processors to get the maximum out of every payment processor.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/4.svg',
                                                        title: 'Order Management',
                                                        // description: 'Whether you are using a single BIN or utilizing its ranges for different programs, our program managers will fulfill all that your card program requires.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/3.svg',
                                                        title: 'Content Management',
                                                        // description: 'Go beyond the basics by creating your own payment APIs',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/7.svg',
                                                        title: 'Social Media Management',
                                                        // description: 'From cardholder portals to dispute management systems to payment processing engines, we will cover it all for you - share your use cases to find out more about how we can help you. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/2.svg',
                                                        title: 'CRM Management ',
                                                        // description: 'Optimize your products with In app provisioning and providing your customers digital wallets to make smarter payments.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/6.svg',
                                                        title: 'Reporting',
                                                        // description: 'Bring banking to your fingertips by consuming our white label solutions - completely customizable to meet all your banking needs. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/5.svg',
                                                        title: 'Product Support ',
                                                        // description: 'Ensure timely resolution of technical queries raised by your customers through voice & non-voice channels. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/bo/8.svg',
                                                        title: 'Technical Support ',
                                                        // description: 'Never miss filing a single dispute again. Our experts monitor your programs closely to ensure that all disputes are timely handled.',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Cloud" title="Cloud">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/1.svg',
                                                        title: 'AWS',
                                                        description: 'Leverage the knowledge of experts to launch serverless applications using instances that support your applications.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/6.svg',
                                                        title: 'Microsoft Azure',
                                                        description: 'Choose a datacenter from multiple zones to enable superior data processing of your SaaS products. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/4.svg',
                                                        title: 'Google Cloud Platform',
                                                        description: 'Utilize GCP to launch scalable and robust serverless applications.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/2.svg',
                                                        title: 'Digital Ocean ',
                                                        description: 'Deploy droplets in no time to host your websites, mobile apps, SaaS products, and much more.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/5.svg',
                                                        title: 'Firebase ',
                                                        description: 'Deploy your applications quickly with minimal coding using Firebase BaaS.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/c/3.svg',
                                                        title: 'Infrastructure Management ',
                                                        description: 'Creating cloud infrastructures isn’t complex, but unnecessary billing can be heavy on your pocket. Redesign your cloud infrastructure with minimal services to maximize your output.',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Security" title="Security & Compliance">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sc/3.svg',
                                                        title: 'SOC Compliance ',
                                                        description: 'Compliance is extremely valuable and it lies at the core of any organizations success factor. We can work with your internal teams and external auditors to get your SOC Type 1 & Type 2 certifications.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sc/1.svg',
                                                        title: 'AOS',
                                                        description: 'Receive your attestation of compliance report to enable your organzation to work with different companies and meet their due diligence checklist items.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sc/4.svg',
                                                        title: 'PCI',
                                                        description: 'Payment card industry compliance is a critical success factor for your applications as you grow your cardholder base and process a large number of transactions. We will set up your organization policies and procedures according to industry standards, implement changes in your system to reflect the best practices, and get your PCI compliance report to enhance your organization`s credibility and reliability.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sc/2.svg',
                                                        title: 'Information Security Trainings ',
                                                        description: 'Our industry expert trainers will provide your resources the training that they need so that they can handle sensitive cardholder data, as well as ensure organization`s security training compliance requirement.',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Quality" title="Quality Assurance">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/qa/1.svg',
                                                        title: 'Manual Testing ',
                                                        description: 'Launch bug free applications for users ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    {/* <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/qa/2.svg',
                                                        title: 'Card Program Implementation',
                                                        description: 'Whether you are using a single BIN or utilizing its ranges for different programs, our program managers will fulfill all that your card program requires.',
                                                    }}>
                                                    </Capabilitie_box> */}
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/qa/3.svg',
                                                        title: 'Automation ',
                                                        description: 'Leverage the power of state-of-the-art test automation solutions for your cloud products. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Design" title="Design UI/UX">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/uiux/1.svg',
                                                        title: 'Smooth Designs',
                                                        description: 'Design trending UIs that users would love to interact with',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/uiux/2.svg',
                                                        title: 'Simplified Workflows',
                                                        description: 'Superior workflows which simplify the entire user journey while keeping the taps and clicks at minimum.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/uiux/3.svg',
                                                        title: 'Unique User Experiences',
                                                        description: 'Create one of a kind user experience for application users by crafting products based on consumer psyche.   ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Data" title="Data Analytics & Reporting">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/da/3.svg',
                                                        title: 'AI/ML',
                                                        // description: 'Integrate your middleware with multiple processors to get the maximum out of every payment processor.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/da/4.svg',
                                                        title: 'Oracle Infrastructure Cloud ',
                                                        // description: 'Whether you are using a single BIN or utilizing its ranges for different programs, our program managers will fulfill all that your card program requires.',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/da/2.svg',
                                                        title: 'Microsoft Power BI ',
                                                        // description: 'Go beyond the basics by creating your own payment APIs',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/da/1.svg',
                                                        title: 'Tableau ',
                                                        // description: 'From cardholder portals to dispute management systems to payment processing engines, we will cover it all for you - share your use cases to find out more about how we can help you. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Virtual" title="Virtual Teams">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/vt/1.svg',
                                                        title: 'Cloud',
                                                        description: 'Hire top talent virtually and run your company on cloud with 24/7 availability. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/vt/2.svg',
                                                        title: 'Talent Acquisition ',
                                                        description: 'Only interview pre-qualified resources to save time. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Client" title="Company Support">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/cs/2.svg',
                                                        title: 'Voice ',
                                                        description: 'Empower your products by providing best in class voice support to your customers',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/cs/1.svg',
                                                        title: 'Non-voice',
                                                        description: 'Let responsive agents handle technical and non-technical customer queries for your products. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Saas" title="Saas Development">
                                            <div className="ct-capabilitiescontent">
                                                <div className="ct-capabilitiesgrid">
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sd/1.svg',
                                                        title: 'FinTech Engineering ',
                                                        description: 'Develop robust transaction processing systems to handle millions of transactions. ',
                                                    }}>
                                                    </Capabilitie_box>
                                                    <Capabilitie_box capabiliti={{
                                                        img: 'image/cp-icon/sd/2.svg',
                                                        title: 'Product Development ',
                                                        description: 'Build smart SaaS products with state-of-the-art technology to meet your business vision',
                                                    }}>
                                                    </Capabilitie_box>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                                {/* <div className="ct-capabilitiescontentholder">
                                    <figure>
                                        <img src="images/capabilities/12.jpg" />
                                    </figure>
                                    <div className="ct-capabilitiescontent">
                                        <div className="ct-capabilitiesgrid">
                                            <Capabilitie_box capabiliti={{
                                                title: 'Voice',
                                                description: 'Empower your products by providing best in class voice support to your customers',
                                            }}>
                                            </Capabilitie_box>
                                            <Capabilitie_box capabiliti={{
                                                title: 'Non-voice',
                                                description: 'Let responsive agents handle technical and non-technical customer queries for your products. ',
                                            }}>
                                            </Capabilitie_box>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="ct-capabilitiesholder">
                        <div className="ct-haslayout ct-sectionspace">
                            <div className="ct-containerfluid">
                                <div className="ct-agileholder">
                                    <div className="ct-agileholdertabs ct-capabilitiestechnolige">
                                        <Tabs defaultActiveKey="D&D" id="uncontrolled-tab-example" className="mb-3">
                                            <Tab eventKey="D&D" title="Design & Development">
                                                <div className="ct-technoliogycontent">
                                                    <p>Upscale your products with the latest cutting-edge design & development technologies. </p>
                                                    <ul>
                                                        <li>
                                                            <span>Design</span>
                                                        </li>
                                                        <li>
                                                            <span>Front-end</span>
                                                        </li>
                                                        <li>
                                                            <span>Back-end</span>
                                                        </li>
                                                        <li>
                                                            <span>Web & Mobile App Development</span>
                                                        </li>
                                                        <li>
                                                            <span>Data Analytics & Reporting</span>
                                                        </li>
                                                        <li>
                                                            <span>Game Development</span>
                                                        </li>
                                                        <li>
                                                            <span>AR/VR Experiences</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <figure className="ct-techimage">
                                                    <img src="/image/d&d.png" />
                                                </figure>
                                            </Tab>
                                            <Tab eventKey="Technologies" title="Technologies">
                                                <div className="ct-technoliogycontent">
                                                    <p>Unleash the Power of Technology by leveraging the latest tools.</p>
                                                    <ul>
                                                        <li>
                                                            <span>Automation</span>
                                                        </li>
                                                        <li>
                                                            <span>Cloud Computing</span>
                                                        </li>
                                                        <li>
                                                            <span>Big Data</span>
                                                        </li>
                                                        <li>
                                                            <span>Artificial Intelligence</span>
                                                        </li>
                                                        <li>
                                                            <span>Machine Learning</span>
                                                        </li>
                                                        <li>
                                                            <span>Blockchain</span>
                                                        </li>
                                                        <li>
                                                            <span>Computer Vision</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <figure className="ct-techimage">
                                                    <img src="/image/tech.png" />
                                                </figure>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <section className='ct-advantges ct-haslayout ct-sectionspace'>
                        <div className='ct-sectionhead'>
                            <div className='ct-sectiontitle'>
                            <h2>Tools & Technologies</h2>
                            </div>
                            <div className='ct-description'>
                            <p>Our rigorous screening process ensures the best talent </p>
                            </div>
                        </div>
                        <div className='ct-advantagesholder'>
                            <Carousel 
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} 
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            >
                            <div>
                                <figure>
                                <img src="/image/recruiters/Advantages/5.svg" />
                                </figure>
                                <h3>Candidate assessment </h3>
                                <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                            </div>
                            <div>
                                <figure>
                                <img src="/image/recruiters/Advantages/4.svg" />
                                </figure>
                                <h3>Skill evaluation </h3>
                                <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                            </div>
                            <div>
                                <figure>
                                <img src="/image/recruiters/Advantages/3.svg" />
                                </figure>
                                <h3>Internal screening</h3>
                                <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                            </div>
                            <div>
                                <figure>
                                <img src="/image/recruiters/Advantages/2.svg" />
                                </figure>
                                <h3>Recommendation </h3>
                                <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                            </div>
                            <div>
                                <figure>
                                <img src="/image/recruiters/Advantages/1.svg" />
                                </figure>
                                <h3>Ongoing monitoring</h3>
                                <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                            </div>
                            </Carousel>
                        </div>
                        </section> */}
                        {/* <div className="ct-ourlocationholder ct-stafingsuccessstory">
                            <div className="ct-sectionspace ct-haslayout">
                                <div className="ct-container">
                                    <div className="ct-sectionhead text-center">
                                        <div className="ct-sectiontitle" data-aos="fade-down">
                                            <h2>Our success stories</h2>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocationcontentholder">
                                        <div className="ct-ourlocations" data-aos="fade-left">
                                            <div className="ct-ourlocation">
                                                <figure>
                                                    <img src="image/1.png" />
                                                </figure>
                                                <div className="ct-ourlocationdetail">
                                                    <h3>Success stories 1</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <Link>Read more</Link>
                                                </div>
                                            </div>
                                            <div className="ct-ourlocation" data-aos="fade-up">
                                                <figure>
                                                    <img src="image/2.png" />
                                                </figure>
                                                <div className="ct-ourlocationdetail">
                                                    <h3> Success stories 2 </h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <Link>Read more</Link>
                                                </div>
                                            </div>
                                            <div className="ct-ourlocation" data-aos="fade-right">
                                                <figure>
                                                    <img src="image/3.png" />
                                                </figure>
                                                <div className="ct-ourlocationdetail">
                                                    <h3>Success stories 3</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    <Link>Read more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </main>
            <Footer />
        </>
    )
}