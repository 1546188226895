import React from 'react';
import ReactDOM from 'react-dom/client';
// import '../public/css/icomoon.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import { BrowserRouter } from "react-router-dom";
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import { instance } from './axiosInstance';
import useLocalStorage from './hooks/useLocalStorage';

const RootComponent = () => {
  const { getData } = useLocalStorage();
  const parseData = JSON.parse(getData('_token'));

  if (parseData?.data?.token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${parseData.data.token}`;
  }
  document.addEventListener('contextmenu', (e) => e.preventDefault());

// Add this code to your main React component
// document.onkeydown = function (e) {
//   if (
//     (e.ctrlKey && e.shiftKey && e.key === 'I') || // For Windows and Linux
//     (e.ctrlKey && e.shiftKey && e.key === 'C') || // For Windows and Linux
//     (e.ctrlKey && e.key === 'U') || // For Windows and Linux
//     (e.metaKey && e.altKey && e.key === 'I')  ||   // For Mac
//     (e.metaKey && e.altKey && e.key === 'C') ||    // For Mac
//     (e.metaKey && e.key === 'U')     // For Mac
//   ) {
//     e.preventDefault();  // Prevent opening developer tools
//   }
// };

// document.oncontextmenu = function (e) {
//   e.preventDefault();  // Prevent right-click context menu
// };


  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<RootComponent />);
reportWebVitals();
