import React from "react";

const data = ["I don’t need it anymore", "reason-2", "reason-3", "reason-4", "other"]

export default function Reason({ isOtherSelected, setIsOtherSelected, reason, setReason }) {
    return (
        <>
            <div className="ct-modalsection">
                <div className={`form-group ${!isOtherSelected ? "mb-4" : ""}`}>
                    <label>Why are you deleting your account?</label>
                    {data.map((el, ind) => (
                        <div className="ct-radiotwo" key={ind}>
                            {el === "other" ?
                                <>
                                    <input type="radio" name="reason" id={`reson-${ind}`} onChange={() => {
                                        setReason("")
                                        setIsOtherSelected(true)
                                    }} />
                                    <label htmlFor={`reson-${ind}`}>{el}</label>
                                </>
                                :
                                <>
                                    <input type="radio" name="reason" value={el} id={`reson-${ind}`} onChange={() => {
                                        setIsOtherSelected(false)
                                        setReason(el)
                                    }} />
                                    <label htmlFor={`reson-${ind}`}>{el}</label>
                                </>
                            }
                        </div>
                    ))}
                </div>
                {isOtherSelected ?
                    <div className="form-group mb-4">
                        <label>Please specify your reason</label>
                        <input type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
                    </div> : null}
            </div>
        </>
    )
}