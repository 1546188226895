import React, {useEffect} from "react";
import reactDom from "react-dom";
import Accordion from 'react-bootstrap/Accordion';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Cooperate() {
    useEffect(() => {
        AOS.init(12000);
      }, []);
  
    return (
        <div className="ct-cooperatecontntholder">
            <div className="ct-collapsedholder" data-aos="fade-right">
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Project-based</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        <span> Discovery: <span>Identify the system which needs to be built</span></span>
                                        
                                    </li>
                                    <li>
                                        <span> Development: <span>Estimate development timeline and technology stack</span></span>
                                        
                                    </li>
                                    <li>
                                        <span> Delivery: <span> Go to market with robust products</span></span>
                                        
                                    </li>
                                </ul>
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Virtual teams</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        <span> Discuss: <span> finalize your requirements with our experts.</span></span>
                                        
                                    </li>
                                    <li>
                                        <span> Way forward Approach: <span> Select the best plan based on your needs.</span></span>
                                        
                                    </li>
                                    <li>
                                        <span>Observe Growth: <span> Get accustomed with your new team members and start accomplishing.</span></span>
                                        
                                    </li>
                                </ul>
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Recruit</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li>
                                        <span> Meet: <span>Meet top talent  </span></span>
                                        
                                    </li>
                                    <li>
                                        <span> Hire: <span>Start working </span></span>
                                    </li>
                                    <li>
                                        <span>Submit: <span>Send us your talent requirements.  </span></span>    
                                    </li>
                                </ul>
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
  }