import * as Yup from "yup";

export const userProfileSchema = Yup.object().shape({
    educations: Yup.array().of(Yup.object().shape({
        institute: Yup.string().required("Institute name is required"),
        degree: Yup.object().shape({
            title: Yup.string().required("Degree title is required"),
            level: Yup.string().required("Degree level is required"),
        }),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
    })),
    certifications: Yup.array().of(Yup.object().shape({
        issuedBy: Yup.string().required("Issued By is required"),
        institute: Yup.string().required("Institute name is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
    })),
    experiences: Yup.array().of(Yup.object().shape({
        jobRole: Yup.string().required("Job role is required"),
        company: Yup.string().required("Company name is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
        details: Yup.string().required("Details are required"),
    })),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('city is required'),
    tools: Yup.array().min(1, "At least one tool and technology is required."),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    experienceLevel: Yup.string().required('Experience level is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    linkedinURL: Yup.string().url('Invalid LinkedIn URL'),
    pdfURL: Yup.mixed().required('Resuma is required'),
    profileSummary: Yup.string().required("Profile summary is required").max(100, 'Profile summary must be at most 100 characters'),
});