import React from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import './about.css'

export default function About_Us(){
    return(
        <div className="ct-aboutussectionone">
            <div className="ct-sectionspace ct-haslayout">
                {/* <div className="ct-container"> */}
                    <div className="ct-aboutuscontentholder">
                        <div className="ct-aboutuscontent">
                            <div className="ct-sectiontitle" data-aos="fade-left">
                                <h2>How We Work With Clients</h2>
                            </div>
                            <div className="ct-description" data-aos="fade-right">
                                <p>We believe in providing value to our clients by timely overcoming industry challenges and offering quality experience. Our onboarding process is simple and fast which allows our clients to be more productive quickly. </p>
                            </div>
                            {/* <Link className="ct-btn">
                                <span>Get Started</span>
                                <i className=""></i>
                            </Link> */}
                            <div className="ct-abutworkgrid" data-aos="fade-down">
                                <div>
                                    <h3>14 years +</h3>
                                    <p>Industry Experience</p>
                                </div>
                                <div>
                                    <h3>15000+</h3>
                                    <p>Network Expert Staff</p>
                                </div>
                                <div>
                                    <h3>50+</h3>
                                    <p>Delivered Projects</p>
                                </div>
                                <div>
                                    <h3>3+</h3>
                                    <p>Continental Coverage</p>
                                </div>
                            </div>
                        </div>
                        <figure data-aos="fade-right">
                            <img src="image/abou-work.png" />
                        </figure>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}