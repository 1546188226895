import React from "react";
import CascadingDropdowns from "./coutry-state-selector";
import { TagsInput } from "react-tag-input-component";

export default function Reffer({ formik, navigation }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="ct-applyjobform">
                <div className="ct-jobpreviewscroll">
                    <div className="ct-formtheme">
                        <fieldset>
                            <div class="ct-jobapplyhead">
                                <h3>Your details</h3>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>First name</label>
                                    <input type="text" {...formik.getFieldProps("firstName")} />
                                    {formik.touched.firstName && formik.errors.firstName && (
                                        <div className="invalid-feedback-custom">{formik.errors.firstName}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Last name </label>
                                    <input type="text" {...formik.getFieldProps("lastName")} />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                        <div className="invalid-feedback-custom">{formik.errors.lastName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" {...formik.getFieldProps("email")} />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="invalid-feedback-custom">{formik.errors.email}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Phone number</label>
                                    <input type="text" {...formik.getFieldProps("phoneNumber")} />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                        <div className="invalid-feedback-custom">{formik.errors.phoneNumber}</div>
                                    )}
                                </div>
                            </div>
                            <div class="ct-jobapplyhead">
                                <h3>Referral’s details</h3>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>First name</label>
                                    <input type="text" {...formik.getFieldProps("r_firstName")} />
                                    {formik.touched.r_firstName && formik.errors.r_firstName && (
                                        <div className="invalid-feedback-custom">{formik.errors.r_firstName}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Last name </label>
                                    <input type="text" {...formik.getFieldProps("r_lastName")} />
                                    {formik.touched.r_lastName && formik.errors.r_lastName && (
                                        <div className="invalid-feedback-custom">{formik.errors.r_lastName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" {...formik.getFieldProps("r_email")} />
                                    {formik.touched.r_email && formik.errors.r_email && (
                                        <div className="invalid-feedback-custom">{formik.errors.r_email}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Phone number</label>
                                    <input type="text" {...formik.getFieldProps("r_phoneNumber")} />
                                    {formik.touched.r_phoneNumber && formik.errors.r_phoneNumber && (
                                        <div className="invalid-feedback-custom">{formik.errors.r_phoneNumber}</div>
                                    )}
                                </div>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>Experience level</label>
                                    <select {...formik.getFieldProps("experienceLevel")}>
                                        <option>--- select ---</option>
                                        <option>Entry-level</option>
                                        <option>Junior (1-2 years)</option>
                                        <option>Mid (2-5 years)</option>
                                        <option>Senior (5-10 years)</option>
                                        <option>Expert (15+ years)</option>
                                    </select>
                                    {formik.touched.experienceLevel && formik.errors.experienceLevel && (
                                        <div className="invalid-feedback-custom">{formik.errors.experienceLevel}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>LinkedIn profile URL (optional)</label>
                                    <input type="text" {...formik.getFieldProps("linkedinURL")} />
                                </div>
                            </div>
                            <CascadingDropdowns formik={formik} />
                            {/* <div className="form-group mb-2">
                                <label>Tools & Technologies</label>
                                <TagsInput
                                    value="sajid"
                                    name="tools"
                                    placeHolder="press Enter to add"
                                />
                            </div> */}
                            {/* <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                </div>
                            </div> */}
                            <div className="ct-uploadfile">
                                <input type="file" id="file" accept="application/pdf" onChange={(e) => formik.setFieldValue('pdfURL', e.target.files[0])} />
                                <label htmlFor="file">
                                    <span className="ct-fileicon">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_618_18588)">
                                                <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                                <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_618_18588">
                                                    <rect width="40" height="40" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    {typeof formik.values.pdfURL !== "string" ?
                                        <h3>{formik.values.pdfURL?.name}</h3> :
                                        <h3>Click to <span>upload</span> resumes</h3>}
                                    <p>(supported format: .pdf)</p>
                                </label>
                                {formik.touched.pdfURL && formik.errors.pdfURL && (
                                    <div className="invalid-feedback-custom">{formik.errors.pdfURL}</div>
                                )}
                            </div>
                            <div className="ct-referapplynote">
                                <p>By submitting a referral, you agree to the <a href="javascript: void(0);">Terms & Conditions</a> for our referral program</p>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            {navigation}
        </form>
    )
}