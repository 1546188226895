import * as Yup from 'yup';

export const basicDetailSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('city is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    pdfURL: Yup.mixed().required('Resuma is required'),
    linkedinURL: Yup.string().url('Invalid LinkedIn URL'),
    experienceLevel: Yup.string().required('Experience level is required'),
    profileSummary: Yup.string().required("Profile summary is required").max(100, 'Profile summary must be at most 100 characters'),
});