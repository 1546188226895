import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import api from "../axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export default function Change_Password() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updatePasswordApi = async (data) => {
        return await api.patch("/applicants/update-password", data)
    }

    const { mutate, isLoading } = useMutation(updatePasswordApi, {
        onSuccess: () => {
            toast.success("password updated successfully")
            handleClose()
        },
        onError: (data) => {
            toast.error(data.response.data.message)
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const { oldPassword, newPassword, confirmPassword } = e.target
        if (newPassword.value !== confirmPassword.value) {
            toast.error("confirm password is not matching with new password")
        } else {
            mutate({ newPassword: newPassword.value, oldPassword: oldPassword.value })
        }
    }

    return (
        <>
            <button className="ct-btn" onClick={handleShow}>Change Password</button>

            <Modal show={show} onHide={handleClose} className="ct-changepasswordmodal">
                <div className="ct-modalform">
                    <form className="ct-formtheme" onSubmit={handleSubmit}>
                        {/* <form className="ct-formtheme"> */}
                        <fieldset>
                            <div className="ct-modaltitle">
                                <div>
                                    <h2>Change Password</h2>
                                </div>
                            </div>
                            <div className="ct-modalsection">
                                <div className="form-group">
                                    <label>Old Password</label>
                                    <input type="password" name="oldPassword" placeholder="***********" required />
                                </div>
                                <div className="ct-inputwidthfifty">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" name="newPassword" placeholder="***********" />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirmPassword" placeholder="***********" />
                                    </div>
                                </div>
                            </div>
                            <div className="ct-btnholder">
                                <Button className="ct-btn ct-btncancel" variant="" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    Cancel
                                </Button>
                                <Button className="ct-btn" type="submit" disabled={isLoading} variant="">
                                    {isLoading ? <Spinner size="sm" /> : "Save"}
                                </Button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Modal>
        </>
    )
}