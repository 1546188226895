import React from "react";
import { toast } from "react-toastify";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import api, { instance } from "../../axiosInstance";

export default function SignupPage() {
    const { setValue } = useLocalStorage()
    const navigate = useNavigate()

    const registerUserApi = async (data) => {
        return await api.post("/applicants/register", data)
    }

    const { mutate, isLoading } = useMutation(registerUserApi, {
        onSuccess: (data) => {
            instance.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`
            setValue("_token", JSON.stringify(data));
            navigate('/profile');
        },
        onError: (data) => {
            toast.error(data.response.data.message)
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const { firstName, lastName, email, password, cpassword } = e.target
        if (password.value !== cpassword.value) {
            toast.error("password and confirm password should be same")
        } else {
            const data = {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                password: password.value,
            }
            mutate({ ...data })
        }
    }

    return (
        <div className="ct-loginwrapper">
            <div className="ct-loginpage">
                <div className="ct-logintwocols">
                    <div className="ct-logincolone">
                        <div className="ct-loginpagelogo">
                            <strong className="ct-logo">
                                <span>
                                    <img src="image/job-apply/logo-black.png" alt="logo" />
                                </span>
                            </strong>
                        </div>
                        <div className="ct-loginform">
                            <form className="ct-formtheme" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>Sign Up</legend>
                                    <div className="ct-inputwidthfifty">
                                        <div className="form-group">
                                            <input type="text" name="firstName" placeholder="Enter Your First Name" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="lastName" placeholder="Enter Your Last Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="email" placeholder="Enter Your Email" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="password" placeholder="Enter Your Password" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="cpassword" placeholder="Enter Your Confirm Password" required />
                                    </div>
                                    <div className="form-group">
                                        <button className="ct-btn ct-btn-lg" type="submit" disabled={isLoading}>{isLoading ? <Spinner size="sm" /> : "Sign Up"}</button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div className="ct-dontaccount">
                            <p>Already have an account? <a href="/login">Log In</a></p>
                        </div>
                    </div>
                    <div className="ct-logincoltwo">
                        <figure>
                            <img src="image/job-apply/login-page.png" alt="login page image" />
                            <figcaption>
                                <span>Balancing progress: Pioneering technology while nurturing well-being</span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}