import React from "react";
import reactDom from "react-dom";
import Header from "../header/Header";
import Banner_image from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Filter from "../main/filter/Filter";
import Short_By from "../main/filter/Short_By";
import All_Jobs from '../main/all_jobs/All_Jobs';

export default function Careers(){
    return(
        <>
        <Header/>
            <Banner_image banner={{
                image: [("images/banner/6.jpg")] 
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-jobsmainholder">
                    <Filter />
                    <Short_By />
                    <All_Jobs />
                    {/* <Jobs /> */}
                    {/* <div className="ct-jobsbtnholder w-full text-center">
                        <Link to="" className="ct-btn">View More</Link>
                    </div>
                    <Perks />
                    <Get_In_Touch /> */}
                </div>
            </main>
            <Footer />
        </>
    )
}