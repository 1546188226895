import React from "react";
import reactDom from "react-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useRef, useState  } from "react";
// import emailjs from "@emailjs/browser";
// import DateRangePicker from 'react-bootstrap-daterangepicker';


export default function Proposal() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const form = useRef();
    // const [selectValue, setSelectValue] = useState("");
  
    // const sendEmail = (e) => {
    //   console.log(e);
    //   e.preventDefault();
    //   console.log(selectValue);
    //   emailjs.sendForm("service_qhgc558", "template_qzoh13j", form.current, "nxvN8hBaUNLCIL73Y").then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    //   e.target.reset();
    // };
    return (
      <>
        <a href="javascript: void(0);" onClick={handleShow} className="ct-btn ct-btnrequestroposal">Request Proposal</a>
  
        <Modal show={show} onHide={handleClose}>
            <div className="ct-modalform">
                <form className="ct-formtheme" >
                {/* <form className="ct-formtheme"> */}
                  <fieldset>
                    <div className="ct-modaltitle">
                      <div>
                        <h2>Request for proposal</h2>
                        <p>How can we help your business?</p>
                      </div>
                    </div>
                    {/* <div class="ct-tooglebutton ct-modaltoggle">
                      <input type="checkbox" name="toggle" id="toggle1" />
                      <label for="toggle1">
                        <span>Clients</span>
                        <span>Recruiters</span>
                      </label>
                    </div> */}
                    <div className="ct-modalsection">
                      <div className="ct-inputwidthfifty">
                        <div className="form-group">
                          <label>Title</label>
                          <input type="text" name="title" placeholder="Project Name" />
                        </div>
                        <div className="form-group">
                          <label>Nature</label>
                          <input type="text" name="nature" placeholder="Project or Extended Team" />
                        </div>
                      </div>
                      <div className="ct-inputwidthfifty">
                        <div className="form-group">
                          <label>Project/Team Detail</label>
                          <input type="text" name="team" placeholder="Here" />
                        </div>
                        <div className="form-group">
                          <label>Project/Augmentation Timeline</label>
                          <input type="text" />
                          {/* <DateRangePicker
                          initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                        >
                          <input type="text" name="date" placeholder="Here" />
                        </DateRangePicker> */}
                        </div>
                      </div>
                      <div className="ct-inputwidthfifty">
                        <div className="form-group">
                          <label>Total number of resources</label>
                          <input type="text" name="number" placeholder="Here" />
                        </div>
                        {/* <div className="form-group">
                          <label>Attach documents</label>
                          <div className="ct-inputfile">
                            <input type="file" id="file" />
                            <label htmlFor="file">
                              <span>Choose File</span>
                              <i className="icon-attachment"></i>
                            </label>
                          </div>
                        </div> */}
                        <div className="form-group">
                          <label>Individual Resource Details</label>
                          <input type="text" name="details" placeholder="Here" />
                        </div>
                      </div>
                      <div className="ct-inputwidthfifty">
                        <div className="form-group">
                          <label>Total number of hours</label>
                          <input type="text" name="hours" placeholder="Hours required per month" />
                        </div>
                        <div className="form-group">
                          <label>Budget </label>
                          <input type="text" name="budget" placeholder="Monthly budget for services" />
                        </div>
                      </div>
                    </div>
                    <div className="ct-btnholder">
                        <Button className="ct-btn ct-btncancel" variant="" onClick={handleClose}>
                        Cancel
                        </Button>
                        <Button className="ct-btn" type="submit" variant="" onClick={handleClose}>
                            Submit Proposal
                        </Button>
                    </div>
                  </fieldset>
                </form>
            </div>
        </Modal>
      </>
    );
  }
  