import React from "react";
import reactDom from "react-dom";
import "../main/tools/tools.css"

export default function About_Us_One(){
    return(
        <div className="ct-aboutuscorevelu">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-container">
                    <div className="ct-sectionhead text-center" data-aos="fade-left">
                        <div className="ct-sectiontitle">
                            <h2>Core values</h2>
                        </div>
                        {/* <div className="ct-description">
                            <p>Consectetur adipisicing elit, sed do eiusmod tempor incididunt utnaitas labore etolore magna aliqua minim veniam quis nostrud exercitation ullamco laboris aliquip commodo consequat aute irure.</p>
                        </div> */}
                    </div>
                    <div className="ct-toolscontent" data-aos="fade-right">
                        <div className="ct-tools">
                            <div className="ct-tool">
                                <img src="/image/about/care-value/1.svg" />
                                <h3>Innovation</h3>
                            </div>
                            <div className="ct-tool">
                                <img src="/image/about/care-value/2.svg" />
                                <h3>Courage</h3>
                            </div>
                            <div className="ct-tool">
                                <img src="/image/about/care-value/3.svg" />
                                <h3>Customer-oriented</h3>
                            </div>
                            <div className="ct-tool">
                                <img src="/image/about/care-value/4.svg" />
                                <h3>Trustworthiness</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}