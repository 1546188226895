import React from "react";
import reactDom from "react-dom";
import Job from "../jobs/job";
import './alljobs.css';

export default function All_Jobs(){
  
    return(
        <div className="ct-alljobsholder">
            <div  className="ct-jobdetailinsidebar">
                <div className="ct-jobsidebarcontent">
                    <button className="ct-btnclosejob" type="button"> <i className="icon-Group-28911"></i> </button>
                    <Job job={{
                        title: 'junior UI/UX Designer',
                        location:"London / Remote",
                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                        date: "02/01/2018,"
                    }}>
                    </Job>
                    <div className="ct-jobfulldetail">
                        <ul>
                            <li>
                                <div className="ct-description">
                                    <p>You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.</p>
                                </div>
                            </li>
                            <li>
                                <div className="ct-description">
                                    <p>You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.</p>
                                </div>
                            </li>
                            <li>
                                <div className="ct-description">
                                    <p>You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.</p>
                                </div>
                            </li>
                            <li>
                                <div className="ct-description">
                                    <p>You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="ct-alljobscontent">
                <div className="ct-jobsholder">
                    <div className="ct-haslayout ct-sectionspace">
                        <div className="ct-container">
                            <div className="ct-jobscontentholder">
                                <span>06 JOBS SEARCHED</span>
                                <div className="ct-jobs">
                                    <Job id="job" job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                    <Job job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                    <Job job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                    <Job job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                    <Job job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                    <Job job={{
                                        id: [("openjobsidebar")],
                                        title: 'Senior UI/UX Designer',
                                        location:"London / Remote",
                                        description: 'You will be responsible for making solution-level decisions after evaluating business needs and analyzing enterprise specifics to determine how technological solutions can support and impact overall business goals and outcomes.',
                                        date: "02/01/2018,"
                                    }}>

                                    </Job>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}