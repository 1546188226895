import React from "react";

export default function Job_Detail({ data }) {
    return (
        <>
            <div className="ct-jobpreviewscroll">
                <div className="ct-jobdatalist">
                    <ul>
                        <li>
                            <span>Job type:</span>
                            <span>{data?.jobType}</span>
                        </li>
                        <li>
                            <span>Experience level:</span>
                            <span>{data?.experienceLevel}</span>
                        </li>
                        <li>
                            <span>Workplace type:</span>
                            <span>{data?.workPlaceType}</span>
                        </li>
                        <li>
                            <span>Monthly salary:</span>
                            <span>{data?.monthlySalary}</span>
                        </li>
                        <li>
                            <span>Location</span>
                            <span>{data?.location}</span>
                        </li>
                        {/* <li>
                            <span>Application deadline:</span>
                            <span>Sep 7, 2023</span>
                        </li> */}
                    </ul>
                </div>
                <div className="ct-jobdeatilbody">
                    <div className="ct-description">
                        <p>{data?.about}</p>
                    </div>
                    <div className="ct-descriptiontitle">
                        <div className="ct-dtitle">
                            <h3>Responsibilities</h3>
                        </div>
                        <div className="ct-description">
                            <p>{data?.rolesAndResponsibility}</p>
                        </div>
                    </div>
                    <div className="ct-descriptiontitle">
                        <div className="ct-dtitle">
                            <h3>Candidate Eligibility</h3>
                        </div>
                        <div className="ct-description">
                            <p>
                                {data?.candidateEligibilty}
                            </p>
                        </div>
                    </div>
                    <div className="ct-descriptiontitle">
                        <div className="ct-dtitle">
                            <h3>Additional Details</h3>
                        </div>
                        <div className="ct-description">
                            <p>
                                {data?.additionalDetails}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}