// api.js
import axios from 'axios';

export const instance = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT_URL}`
});

const api = {
    get: (url, params, headers) => instance.get(url, { params }, headers),
    post: (url, data, headers) => instance.post(url, data, headers),
    patch: (url, data, headers) => instance.patch(url, data, headers),
    delete: (url) => instance.delete(url),
    // You can add more methods as needed
};

export default api;
