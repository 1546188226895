import React, {useEffect} from "react";
import reactDom from "react-dom";
import Cooperate from "./Cooperate";
import './cooperate.css';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Cooperates(){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-cooperatesholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-containerfluid">
                    <div className="ct-cooperatetitlearea">
                        <div className="ct-sectionhead">
                            <div className="ct-sectiontitle">
                                <h2 data-aos="fade-right">How we cooperate with you</h2>
                            </div>
                            <div className="ct-description" data-aos="fade-left">
                                <p>Choose what suits you best and get things done, quickly. </p>
                            </div>
                        </div>
                    </div>
                    <Cooperate />
                </div>
            </div>
        </div>
    )
}