// import react from 'react';
import React, { useRef, useState, useEffect } from "react";
import reactDom from 'react-dom';
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Banner_image from "../banner/Banner_image";
import Get_In_Touch from "../contact/Get_In_Touch";
// import './partner.css';
import { Link } from "react-router-dom";
import './staff.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
// const responsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 5  
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1
//   }
// };


export default function Staffing(){ 
  useEffect(() => {
    AOS.init(2000);
  }, []); 
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/it_stafing.png")] ,
                title: 'Only Top Talent to Help You Grow',
                description: 'We have the most qualified pool of screened candidates to help you succeed in different service industries and projects.',
            }} />
            <Sidebar />
            <main className="ct-main">
              <div className='ct-recuriter'>
                <section className="ct-haslayout ct-sectionspace ct-benifatspartner">
                  <div className="ct-containerfluid">
                    <div className="ct-benifatescontentholder">
                      <div className="ct-sectionhead" data-aos="fade-left">
                        <div className="ct-sectiontitle">
                          <h2>How we can help</h2>
                        </div>
                        <div className="ct-description">
                          <p>Only hire the best pre-screened candidates through our staff augmentation services. Simply submit job requirements, finalize candidates, and start working. It is as simple as 1, 2, 3! </p>
                        </div>
                      </div>
                      <figure className="ct-benifatimageholder"  data-aos="fade-right">
                        <img src="/image/it-staffing/1.jpg" />
                      </figure>
                    </div>
                  </div>
                </section>
                <section className="ct-uniqestafing ct-haslayout ct-sectionspace">
                    <div className="ct-containerfluid">
                        <div className="ct-sectionhead">
                            <div className="ct-sectiontitle" data-aos="fade-down">
                                <h2>A unique staffing experience for all your business needs</h2>
                            </div>
                            <div className="ct-description" data-aos="fade-up">
                                <p>Our process is simple yet refined to help you get back on track immediately and focus on what matters the most to your business – results.</p>
                            </div>
                        </div>
                        <div className="ct-uniqestafinggrid">
                            <div data-aos="fade-left">
                                <span>01</span>
                                <h4>Talk with our staffing experts</h4>
                            </div>
                            <div  data-aos="fade-left">
                                <span>02</span>
                                <h4>Finalize resources</h4>
                            </div>
                            <div data-aos="fade-up">
                                <span>03</span>
                                <h4>Interview & finalize team</h4>
                            </div>
                            <div data-aos="fade-right">
                                <span>04</span>
                                <h4>Select most suitable subscription</h4>
                            </div>
                            <div data-aos="fade-right">
                                <span>05</span>
                                <h4>Start working</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ct-haslayout ct-sectionspace ct-benifatspartner">
                  <div className="ct-containerfluid">
                    <div className="ct-benifatescontentholder">
                      <div className="ct-sectionhead"  data-aos="fade-down">
                        <div className="ct-sectiontitle">
                          <h2>Benefits</h2>
                        </div>
                        <div className="ct-description">
                          <p>Enrolling for augmentation services makes all the financial sense for your organization considering that you call the shots, and we get things done with ZERO management or legal liability. Upgrade monthly subscription anytime and revise team members without effecting the contract:</p>
                        </div>
                        <div className="ct-benifatgrid">
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/3.svg" />
                            </span>
                            <h5>Zero HR overhead costs</h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/4.svg" />
                            </span>
                            <h5>Zero IRA liability</h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/1.svg" />
                            </span>
                            <h5>Low Taxation</h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/2.svg" />
                            </span>
                            <h5>Zero bonus liability</h5>
                          </div>
                        </div>
                      </div>
                      <figure className="ct-benifatimageholder"  data-aos="fade-up">
                        <img src="/image/stafing.png" />
                      </figure>
                    </div>
                  </div>
                </section>
                {/* <section className='ct-advantges ct-haslayout ct-sectionspace'>
                <div className="ct-containerfluid">
                  <div className='ct-sectionhead'>
                    <div className='ct-sectiontitle'>
                      <h2>How we function</h2>
                    </div>
                    <div className='ct-description'>
                      <p>Our rigorous screening process ensures the best talent </p>
                    </div>
                  </div>
                  <div className='ct-advantagesholder'>
                      <div className="ct-advantagesitem">
                        <figure>
                          <img src="/image/recruiters/Advantages/5.svg" />
                        </figure>
                        <h3>Candidate assessment </h3>
                        <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                      </div>
                      <div className="ct-advantagesitem">
                        <figure>
                          <img src="/image/recruiters/Advantages/4.svg" />
                        </figure>
                        <h3>Skill evaluation </h3>
                        <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                      </div>
                      <div className="ct-advantagesitem">
                        <figure>
                          <img src="/image/recruiters/Advantages/3.svg" />
                        </figure>
                        <h3>Internal screening</h3>
                        <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                      </div>
                      <div className="ct-advantagesitem">
                        <figure>
                          <img src="/image/recruiters/Advantages/2.svg" />
                        </figure>
                        <h3>Recommendation </h3>
                        <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                      </div>
                      <div className="ct-advantagesitem">
                        <figure>
                          <img src="/image/recruiters/Advantages/1.svg" />
                        </figure>
                        <h3>Ongoing monitoring</h3>
                        <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                      </div>
                  </div>
                </div>
                </section> */}
                {/* <div className="ct-ourlocationholder ct-stafingsuccessstory">
                    <div className="ct-sectionspace ct-haslayout">
                        <div className="ct-container">
                            <div className="ct-sectionhead text-center">
                                <div className="ct-sectiontitle">
                                    <h2>Our success stories</h2>
                                </div>
                            </div>
                            <div className="ct-ourlocationcontentholder">
                                <div className="ct-ourlocations">
                                    <div className="ct-ourlocation"  data-aos="fade-left">
                                        <figure>
                                            <img src="image/1.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 1</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-down">
                                        <figure>
                                            <img src="image/2.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3> Success stories 2 </h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/3.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 3</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Get_In_Touch />
              </div>
            </main>
            <Footer />
        </>
    )
}