import * as Yup from "yup";

export const workExperienceSchema = Yup.object().shape({
    educations: Yup.array().of(Yup.object().shape({
        institute: Yup.string().required("Institute name is required"),
        degree: Yup.object().shape({
            title: Yup.string().required("Degree title is required"),
            level: Yup.string().required("Degree level is required"),
        }),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
    })),
    certifications: Yup.array().of(Yup.object().shape({
        issuedBy: Yup.string().required("Issued By is required"),
        institute: Yup.string().required("Institute name is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
    })),
    experiences: Yup.array().of(Yup.object().shape({
        jobRole: Yup.string().required("Job role is required"),
        company: Yup.string().required("Company name is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
        details: Yup.string().required("Details are required"),
    })),
    tools: Yup.array().min(1, "At least one tool and technology is required."),
});