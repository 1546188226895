import React, { useEffect } from "react";
import reactDom from "react-dom";
import Tabs from 'react-bootstrap/Tabs';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Tab from 'react-bootstrap/Tab';
export default function Capabilitiestabs(){
    // useEffect(() =>{
        
    // }, [])
    return(
        <Tabs defaultActiveKey="Financial" id="uncontrolled-tab-example" className="mb-3 32123">
            <Tab eventKey="Financial" title="Financial Services">
                <div className="ct-tabscontent ct-financialtabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/fs/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/4.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/5.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/6.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/7.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/fs/8.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="profile" title="SaaS Development ">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/sd/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/sd/2.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Client" title="Client Support">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/cs/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cs/2.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Application" title="Application Development">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/ad/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/ad/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/ad/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/ad/4.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Virtual" title="Virtual Teams">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/vt/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/vt/2.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Management" title="Project Management">
                <div className="ct-tabscontent ct-projecttabs">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/pm/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/pm/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/pm/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/pm/4.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Infrastructure" title="Data Analytics & reporting">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/da/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/da/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/da/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/da/4.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Operations" title="Back-office Operations">
                <div className="ct-tabscontent ct-operationstabs">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/boo/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/4.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/5.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/6.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/7.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/8.svg" /></span>
                        </li>
                        {/* <li>
                            <span><img src="/image/hp-capa/boo/4.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/boo/4.svg" /></span>
                        </li> */}
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Design" title="Design UI/UX">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/uiux/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/uiux/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/uiux/3.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Cloud" title="Cloud">
                <div className="ct-tabscontent ct-cloudtabs">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/cloud/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cloud/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cloud/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cloud/4.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cloud/5.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/cloud/6.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Quality" title="Quality Assurance">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/qa/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/qa/2.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
            <Tab eventKey="Security" title="Security & Compliance">
                <div className="ct-tabscontent">
                    <ul>
                        <li>
                            <span><img src="/image/hp-capa/sc/1.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/sc/2.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/sc/3.svg" /></span>
                        </li>
                        <li>
                            <span><img src="/image/hp-capa/sc/4.svg" /></span>
                        </li>
                    </ul>
                </div>
            </Tab>
        </Tabs>
    )
}