export default function useLocalStorage() {
  const setValue = (key, data) => {
    localStorage.setItem(key, data);
  };
  const removeSession = () => {
    localStorage.removeItem("_token");
  };
  const getData = (key) => {
    return localStorage.getItem(key);
  };

  return { setValue, removeSession, getData };
}
