import { Route, Routes } from "react-router-dom";
import UserProfile from "../user-profile/User-Profile"

export default function PrivateRoutes() {
    return (
        <Routes>
            <Route path='profile' element={<UserProfile />} />
        </Routes>
    );
}
