import React from "react";
import Tabs from 'react-bootstrap/Tabs';
import Header from "../components/header/Header"
import Tab from 'react-bootstrap/Tab';
import BannerImage from "../components/banner/Banner_image";
import Sidebar from "../components/sidebar/Sidebar";
import { TagsInput } from "react-tag-input-component";
import ChangePassword from "./Change_Password";
import Deleteaccount from "./Delete_account";
import { isString, useFormik } from "formik";
import { userProfileSchema } from "../components/main/jobs/schemas/user-profileSchema";
import useLocalStorage from "../hooks/useLocalStorage";
import { useMutation, useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import CascadingDropdowns from "../components/main/jobs/coutry-state-selector";
import api from "../axiosInstance";

export default function UserProfile() {
    const { getData } = useLocalStorage();
    // const [file, setFile] = useState("")

    const parsedData = JSON.parse(getData("_token"));
    const updateApplicantApi = async (data) => {
        return await api.patch(`/applicants/update/${parsedData?.data?.user?._id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const { mutate, isLoading: isUpdatingApplicant } = useMutation(updateApplicantApi, {
        onSuccess: (data) => {
            toast.success("profile updated successfully")
            formik.setValues({
                firstName: data.data.user.firstName,
                lastName: data.data.user.lastName,
                email: data.data.user.email,
                country: data.data.user.country ? JSON.stringify(data.data.user.country) : "",
                state: data.data.user.state ? JSON.stringify(data.data.user.state) : "",
                city: data.data.user.city,
                phoneNumber: data.data.user.phoneNumber,
                linkedinURL: data.data.user.linkedinURL,
                profileSummary: data.data.user.profileSummary,
                educations: data.data.user.educations.length ? data.data.user.educations : [{ institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }],
                certifications: data.data.user.certifications.length ? data.data.user.certifications : [{ institute: "", startDate: "", endDate: "" }],
                experiences: data.data.user.experiences.length ? data.data.user.experiences : [{ jobRole: "", company: "", startDate: "", endDate: "", details: "" }],
                tools: data.data.user.tools,
                pdfURL: data.data.user.pdfURL
            })
        },
        onError: (data) => {
            toast.error(data.response.data.message)
        }
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            linkedinURL: "",
            profileSummary: "",
            educations: [{ institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }],
            certifications: [{ institute: "", startDate: "", endDate: "" }],
            experiences: [{ jobRole: "", company: "", startDate: "", endDate: "", details: "" }],
            tools: [],
            country: "",
            state: "",
            city: "",
            pdfURL: null,
            experienceLevel: ""
        },
        validationSchema: userProfileSchema,
        onSubmit: (values) => {
            const formData = new FormData()
            if (typeof values.pdfURL !== "string") {
                formData.append("file", values.pdfURL)
            }
            formData.append("data", JSON.stringify({ ...values, country: JSON.parse(values.country), state: JSON.parse(values.state) }))
            mutate(formData)
        },
    });

    const getCurrentApplicantApi = async (data) => {
        return await api.get(`/applicants/current-applicant/${data.queryKey[1]}`)
    }
    const { data, isLoading, refetch } = useQuery(
        ["get-current-applicant", parsedData?.data?.user?._id],
        getCurrentApplicantApi,
        {
            onSuccess: (data) => {
                formik.setValues({
                    firstName: data.data.user.firstName,
                    lastName: data.data.user.lastName,
                    email: data.data.user.email,
                    phoneNumber: data.data.user.phoneNumber,
                    linkedinURL: data.data.user.linkedinURL,
                    profileSummary: data.data.user.profileSummary,
                    educations: data.data.user.educations.length ? data.data.user.educations : [{ institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }],
                    certifications: data.data.user.certifications.length ? data.data.user.certifications : [{ institute: "", startDate: "", endDate: "" }],
                    experiences: data.data.user.experiences.length ? data.data.user.experiences : [{ jobRole: "", company: "", startDate: "", endDate: "", details: "" }],
                    tools: data.data.user.tools,
                    country: data.data.user.country ? JSON.stringify(data.data.user.country) : "",
                    state: data.data.user.state ? JSON.stringify(data.data.user.state) : "",
                    city: data.data.user.city,
                    pdfURL: data.data.user.pdfURL ? data.data.user.pdfURL : "",
                    experienceLevel: data.data.user.experienceLevel
                })
            },
            refetchOnWindowFocus: false,
        },
    );

    return (
        <>
            <Header />
            <div className="ct-bannerwithoutimage"></div>
            {/* <BannerImage banner={{
                image: [("image/about-banner.png")],
                title: 'Delivering value that businesses deserve Since 2009',
                description: 'We are driven by change and motivated by achievements. Consforc is one of the most trusted & reliable technology partners, and next destination for many startups.',
            }} /> */}
            {/* <Sidebar /> */}
            <main className="ct-main ct-haslayout">
                <div className="ct-container">
                    {isLoading ? <div style={{ textAlign: "center", height: 300, paddingTop: 15 }}>
                        <Spinner />
                    </div> :
                        <div className="ct-profiletabs">
                            <div className="ct-tabscreatbtn">
                                <Tabs
                                    defaultActiveKey="details"
                                    id="uncontrolled-tab-example"
                                    className="my-5 ct-tabsnavholder"
                                >
                                    <Tab eventKey="details" title="Profile details">
                                        <div className="ct-applyjobform">
                                            <div className="ct-profiletitle">
                                                <h4>Profile details</h4>
                                            </div>
                                            <form className="ct-formtheme" onSubmit={formik.handleSubmit}>
                                                <fieldset>
                                                    <div className="ct-inputwidthfifty">
                                                        <div className="form-group">
                                                            <label>First name</label>
                                                            <input type="text" {...formik.getFieldProps("firstName")} />
                                                            {formik.touched.firstName && formik.errors.firstName && (
                                                                <div className="invalid-feedback-custom">{formik.errors.firstName}</div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Last name </label>
                                                            <input type="text" {...formik.getFieldProps("lastName")} />
                                                            {formik.touched.lastName && formik.errors.lastName && (
                                                                <div className="invalid-feedback-custom">{formik.errors.lastName}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="ct-inputwidthfifty">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="text" {...formik.getFieldProps("email")} />
                                                            {formik.touched.email && formik.errors.email && (
                                                                <div className="invalid-feedback-custom">{formik.errors.email}</div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Phone number</label>
                                                            <input type="text" {...formik.getFieldProps("phoneNumber")} />
                                                            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                                                <div className="invalid-feedback-custom">{formik.errors.phoneNumber}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="ct-inputwidthfifty">
                                                        <div className="form-group">
                                                            <label>Experience level</label>
                                                            <select {...formik.getFieldProps("experienceLevel")}>
                                                                <option>--- select ---</option>
                                                                <option>Entry-level</option>
                                                                <option>Junior (1-2 years)</option>
                                                                <option>Mid (2-5 years)</option>
                                                                <option>Senior (5-10 years)</option>
                                                                <option>Expert (15+ years)</option>
                                                            </select>
                                                            {formik.touched.experienceLevel && formik.errors.experienceLevel && (
                                                                <div className="invalid-feedback-custom">{formik.errors.experienceLevel}</div>
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label>LinkedIn profile URL (optional)</label>
                                                            <input type="text" {...formik.getFieldProps("linkedinURL")} />
                                                        </div>
                                                    </div>
                                                    <CascadingDropdowns formik={formik} />
                                                    <div className="form-group">
                                                        <label>Profile Summary</label>
                                                        <textarea placeholder="0/100 words" {...formik.getFieldProps("profileSummary")}></textarea>
                                                        {formik.touched.profileSummary && formik.errors.profileSummary && (
                                                            <div className="invalid-feedback-custom">{formik.errors.profileSummary}</div>
                                                        )}
                                                    </div>
                                                    <div className="ct-uploadfile">
                                                        <input type="file" id="file" accept="application/pdf" onChange={(e) => formik.setFieldValue('pdfURL', e.target.files[0])} />
                                                        <label htmlFor="file">
                                                            <span className="ct-fileicon">
                                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_618_18588)">
                                                                        <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                                                        <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_618_18588">
                                                                            <rect width="40" height="40" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            {formik.values.pdfURL !== null && typeof formik.values.pdfURL !== "string" ?
                                                                <h3>{formik.values.pdfURL?.name}</h3> :
                                                                <h3>Click to <span>upload</span> resumes</h3>}
                                                            <p>(supported format: .pdf)</p>
                                                        </label>
                                                        {isString(formik.values.pdfURL) && formik.values.pdfURL ?
                                                            <div className="ct-uploadedresumes pt-1 pb-4">
                                                                <ul>
                                                                    <li>
                                                                        <div style={{ display: "flex" }}>
                                                                            <em>
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M16.6922 6.43281L12.3172 2.05781C12.2591 1.99979 12.1902 1.95378 12.1143 1.92241C12.0384 1.89105 11.9571 1.87494 11.875 1.875H4.375C4.04348 1.875 3.72554 2.0067 3.49112 2.24112C3.2567 2.47554 3.125 2.79348 3.125 3.125V16.875C3.125 17.2065 3.2567 17.5245 3.49112 17.7589C3.72554 17.9933 4.04348 18.125 4.375 18.125H15.625C15.9565 18.125 16.2745 17.9933 16.5089 17.7589C16.7433 17.5245 16.875 17.2065 16.875 16.875V6.875C16.8751 6.7929 16.859 6.71159 16.8276 6.63572C16.7962 6.55985 16.7502 6.4909 16.6922 6.43281ZM11.875 6.875V3.4375L15.3125 6.875H11.875Z" fill="#00C981" />
                                                                                </svg>
                                                                            </em>
                                                                            <h4 className="mb-0">{formik.values.pdfURL.split("/")[3]}</h4>
                                                                            {/* <span>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM18 19.5H6V6H18V19.5ZM7.5 2.25C7.5 2.05109 7.57902 1.86032 7.71967 1.71967C7.86032 1.57902 8.05109 1.5 8.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25C16.5 2.44891 16.421 2.63968 16.2803 2.78033C16.1397 2.92098 15.9489 3 15.75 3H8.25C8.05109 3 7.86032 2.92098 7.71967 2.78033C7.57902 2.63968 7.5 2.44891 7.5 2.25Z" fill="#E03E3E" />
                                                                            </svg>
                                                                        </span> */}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div> : null}
                                                        {formik.touched.pdfURL && formik.errors.pdfURL && (
                                                            <div className="invalid-feedback-custom">{formik.errors.pdfURL}</div>
                                                        )}
                                                    </div>
                                                    <div className="ct-initialquestionwidget">
                                                        {formik.values.educations.map((education, index) => (
                                                            <div key={index}>
                                                                <div className="form-group mb-2">
                                                                    <label>Education</label>
                                                                    <input type="text" placeholder="Institute name"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={education.institute}
                                                                        name={`educations[${index}].institute`}
                                                                    />
                                                                    {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].institute && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.educations[index].institute}</div>
                                                                    )}
                                                                </div>
                                                                <div className="ct-selectplusinput mb-2">
                                                                    <div className="ct-selectarea">
                                                                        <select className="at-selecttheme"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            defaultValue={education.degree.level}
                                                                            name={`educations[${index}].degree.level`}
                                                                        >
                                                                            <option>Degree level</option>
                                                                            <option>Bachelors</option>
                                                                            <option>Masters</option>
                                                                        </select>
                                                                        <div className="ct-inputwithicon">
                                                                            <input type="text" placeholder="degree title"
                                                                                name={`educations[${index}].degree.title`}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={education.degree.title}
                                                                            />
                                                                            <i>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM18 19.5H6V6H18V19.5ZM7.5 2.25C7.5 2.05109 7.57902 1.86032 7.71967 1.71967C7.86032 1.57902 8.05109 1.5 8.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25C16.5 2.44891 16.421 2.63968 16.2803 2.78033C16.1397 2.92098 15.9489 3 15.75 3H8.25C8.05109 3 7.86032 2.92098 7.71967 2.78033C7.57902 2.63968 7.5 2.44891 7.5 2.25Z" fill="#E03E3E" />
                                                                                </svg>
                                                                            </i>
                                                                        </div>
                                                                        {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].degree && formik.errors.educations[index].degree.title && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.educations[index].degree.title}</div>
                                                                        )}
                                                                        {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].degree && formik.errors.educations[index].degree.level && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.educations[index].degree.level}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="ct-inputwidthfifty">
                                                                    <div className="form-group">
                                                                        <input type="date"
                                                                            name={`educations[${index}].startDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={education.startDate}
                                                                        />
                                                                        {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].startDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.educations[index].startDate}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="date"
                                                                            name={`educations[${index}].endDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={education.endDate}
                                                                        />
                                                                        {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].endDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.educations[index].endDate}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="form-group ct-btnaddoption">
                                                            <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("educations", [...formik.values.educations, { institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }])}>
                                                                <em>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                                                    </svg>
                                                                </em>
                                                                <span>Add option</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="ct-initialquestionwidget">
                                                        {formik.values.certifications.map((certification, index) => (
                                                            <div key={index}>
                                                                <div className="form-group mb-2">
                                                                    <label>Certification</label>
                                                                    <input type="text" placeholder="Issued By"
                                                                        name={`certifications[${index}].issuedBy`}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={certification.issuedBy}
                                                                    />
                                                                    {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].issuedBy && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.certifications[index].issuedBy}</div>
                                                                    )}
                                                                </div>
                                                                <div className="form-group mb-2">
                                                                    <input type="text" placeholder="Institute name"
                                                                        name={`certifications[${index}].institute`}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={certification.institute}
                                                                    />
                                                                    {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].institute && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.certifications[index].institute}</div>
                                                                    )}
                                                                </div>
                                                                <div className="ct-inputwidthfifty">
                                                                    <div className="form-group">
                                                                        <input type="date"
                                                                            name={`certifications[${index}].startDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={certification.startDate}
                                                                        />
                                                                        {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].startDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.certifications[index].startDate}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="date"
                                                                            name={`certifications[${index}].endDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={certification.endDate}
                                                                        />
                                                                        {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].endDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.certifications[index].endDate}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="form-group ct-btnaddoption">
                                                            <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("certifications", [...formik.values.certifications, { institute: "", startDate: "", endDate: "" }])}>
                                                                <em>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                                                    </svg>
                                                                </em>
                                                                <span>Add option</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <label>Tools & Technologies</label>
                                                        <TagsInput
                                                            value={formik.values.tools}
                                                            onChange={(tags) => formik.setFieldValue("tools", tags)}
                                                            name="tools"
                                                            onBlur={formik.handleBlur}
                                                            placeHolder="press Enter to add"
                                                        />
                                                        {formik.touched.tools && formik.errors.tools && (
                                                            <div className="invalid-feedback-custom">{formik.errors.tools}</div>
                                                        )}
                                                    </div>
                                                    <div className="ct-initialquestionwidget">
                                                        {formik.values.experiences.map((experience, index) => (
                                                            <div key={index}>
                                                                <div className="form-group mb-2">
                                                                    <label>Work Experience</label>
                                                                    <input type="text" placeholder="Job role"
                                                                        name={`experiences[${index}].jobRole`}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={experience.jobRole}
                                                                    />
                                                                    {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].jobRole && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.experiences[index].jobRole}</div>
                                                                    )}
                                                                </div>
                                                                <div className="form-group mb-2">
                                                                    <input type="text" placeholder="Company name"
                                                                        name={`experiences[${index}].company`}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={experience.company}
                                                                    />
                                                                    {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].company && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.experiences[index].company}</div>
                                                                    )}
                                                                </div>
                                                                <div className="ct-inputwidthfifty">
                                                                    <div className="form-group">
                                                                        <input type="date" placeholder="Start date"
                                                                            name={`experiences[${index}].startDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={experience.startDate}
                                                                        />
                                                                        {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].startDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.experiences[index].startDate}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="date" placeholder="End date"
                                                                            name={`experiences[${index}].endDate`}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={experience.endDate}
                                                                        />
                                                                        {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].endDate && (
                                                                            <div className="invalid-feedback-custom">{formik.errors.experiences[index].endDate}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <textarea placeholder="Details"
                                                                        name={`experiences[${index}].details`}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={experience.details}
                                                                    ></textarea>
                                                                    {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].details && (
                                                                        <div className="invalid-feedback-custom">{formik.errors.experiences[index].details}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="form-group ct-btnaddoption">
                                                            <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("experiences", [...formik.values.experiences, { jobRole: "", company: "", startDate: "", endDate: "", details: "" }])}>
                                                                <em>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                                                    </svg>
                                                                </em>
                                                                <span>Add option</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="ct-btnholder">
                                                        <button className="ct-btn" disabled={isUpdatingApplicant} type="submit">{isUpdatingApplicant ? <Spinner size="sm" /> : "Save"}</button>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="settings" title="Account settings">
                                        <div className="ct-accountsetting">
                                            <div className="ct-profiletitle">
                                                <h4>Account settings</h4>
                                                <ul>
                                                    <li>
                                                        <ChangePassword />
                                                    </li>
                                                    <li>
                                                        <Deleteaccount />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ct-accountsettingcontent">
                                                <ul>
                                                    <li>
                                                        <span>Email address</span>
                                                        <span>{data?.data?.user.email}</span>
                                                    </li>
                                                    <li>
                                                        <span>Notifications</span>
                                                        <span>Send email notifications</span>
                                                        <div className="ct-checkboxtoggle">
                                                            <input type="checkbox" id="checkbox" />
                                                            <label htmlFor="checkbox"></label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </>
    )
}