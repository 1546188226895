import React from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import "./getin.css";
import { useRef, useState  } from "react";
import emailjs from "@emailjs/browser";



export default function Get_In_Touch_Two(){
    const form = useRef();
    const [selectValue, setSelectValue] = useState("");
  
    const sendEmail = (e) => {
      console.log(e);
      e.preventDefault();
      console.log(selectValue);
      emailjs.sendForm("service_qhgc558", "template_j6oafvi", form.current, "nxvN8hBaUNLCIL73Y").then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
    };
    return(
        <div className="ct-getintouchholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-container">
                    <div className="ct-getintouchcontentholder">
                        <div className="ct-getintouchcontent">
                            <div className="ct-setionhead">
                                <div className="ct-sectiontitle">
                                    <h2>Get in touch</h2>
                                </div>
                                {/* <div className="ct-getintouchcontentgrid"> */}
                                <div className="ct-contactinfodetailcol">
                                    <div className="ct-contactcolone">
                                        <div className="ct-getintouchgriditem">
                                            <h5>Corporate Headquarter</h5>
                                            <ul>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-location2"></i>
                                                        <span>67/A B1, Gulberg III, Lahore, Pakistan</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-Icon_Call"></i>
                                                        <span>(+92) 321-4016-167</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ct-getintouchgriditem">
                                            <h5>Address</h5>
                                            <ul>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-location2"></i>
                                                        <span>617 D, Airport Rd, Lahore, Pakistan</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-Icon_Call"></i>
                                                        <span>(+92) 324 444 4431</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ct-contactcolone">
                                        <div className="ct-getintouchgriditem">
                                            <h5>US Addresses</h5>
                                            <ul>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-location2"></i>
                                                        <span>3760 Saint James CT, Elgin, IL 60124</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-Icon_Call"></i>
                                                        <span>847 857 7699</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className="ct-getintouchgriditem">
                                            <h5></h5>
                                            <ul>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-location2"></i>
                                                        <span>36 Fox Run Rd, Sudbury, MA 01776</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript: void(0);">
                                                        <i className="icon-Icon_Call"></i>
                                                        <span>847 219 9994</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                    
                                {/* </div> */}
                                <div className="ct-getintouchcontentgrid">
                                    <div className="ct-getintouchgriditem">
                                        <h5>General Inquiries</h5>
                                        <ul>
                                            <li>
                                                <a href="javascript: void(0);">
                                                    <i className="icon-Icon_Call"></i>
                                                    <span> 847 802 9741</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ct-getintouchgriditem">
                                        <h5>Mail Collection</h5>
                                        <ul>
                                            <li>
                                                <a href="javascript: void(0);">
                                                    <i className="icon-location2"></i>
                                                    <span>PO Box 5355, Wayland, MA 01778</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-getintouchform">
                            <form className="ct-formtheme"  ref={form} onSubmit={sendEmail} >
                            {/* <form className="ct-formtheme"> */}
                                <fieldset>
                                    <div className="form-group">
                                        <input type="text" name="full_name" placeholder="Full Name" />
                                    </div>
                                    <div className="ct-inputwidthfifty">
                                        <div className="form-group">
                                            <input type="text" name="email" placeholder="Email*" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="phone" placeholder="Mobile number" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="subject" placeholder="Subject" />
                                    </div>
                                    <div className="form-group">
                                        <textarea name="message" placeholder="Drop us a message here…"></textarea>
                                    </div>
                                    <button type="submit" className="ct-btn">Submit</button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}