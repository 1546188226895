import Home from "./components/home/Home";
import About from "./components/about-us/About";
import Capabilities from "./components/capabilities/Capabilities";
import Contact from "./components/contact/Contact";
import Careers from './components/careers/Careers';
import AllJobs from './components/all-jobs/Careers';
import Insights from './components/insight/Insights';
import Staffing from './components/staffing/Staffing';
import Partner from './components/partner/Partner';
import HowItWorks from './components/how-it-works/How_It_Works';
import People from './components/people/people';
import Refer from './components/refer/refer';
import { QueryClient, QueryClientProvider } from "react-query";
import LoginPage from './auth/login/Login';
import SignupPage from './auth/signup/Signup';
import { ToastContainer } from 'react-toastify';
import PublicRoute from "./routes/PublicRoute";
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from "./routes/dashboardRoutes";
import PrivateRoute from "./routes/PrivateRoute";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="App ct-wrapper">
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path='/signup' element={<SignupPage />} />
              <Route path='/login' element={<LoginPage />} />
            </Route>
            <Route exact path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/capabilities' element={<Capabilities />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/insights' element={<Insights />} />
            <Route path='/staffing' element={<Staffing />} />
            <Route path='/recruiter' element={<Partner />} />
            <Route path='/careers' element={<Careers />} />
            <Route path='/all_jobs' element={<AllJobs />} />
            <Route path='/how-it-works' element={<HowItWorks />} />
            <Route path='/people' element={<People />} />
            <Route path='/referral' element={<Refer />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="/*"
                element={
                  <PrivateRoutes />
                }

              />
            </Route>
          </Routes>
        </div>
      </QueryClientProvider >
      <ToastContainer />
    </>
  );
}

export default App;


// {/* </Router> */}
// {/* <Home/>
// <About /> */}
// {/* <Route exact path='/new_job' component={New_Job} />
// <Route exact path='/login' component={Login} />
// <Route exact path='/forgot' component={Forgot} />
// <Route exact path='/reset' component={Reset} /> */}
// {/* <Route exact path='/dashboard' component={Dashboard} />
// <Route exact path='/insight_dashboard' component={Insights_Dashboard} />
// <Route exact path='/add_insights' component={Add_Insights} /> */}