export default function Initial_Screening({ data, handleSubmit, applicantsAnswers, handleInputChange, handleBlur, formErrors, navigation }) {
    return (
        <form onSubmit={handleSubmit}>
            <div className="ct-applyjobform">
                <div className="ct-jobpreviewscroll">
                    <div className="ct-formtheme">
                        <fieldset>
                            {data?.questions.map((el, index) => (
                                <div key={index}>
                                    {el.type === "TA" ? (
                                        <div className="form-group">
                                            <label>{el.question}</label>
                                            <input
                                                type="text"
                                                name={`answer${index}`}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onBlur={() => handleBlur(index)}
                                                value={applicantsAnswers[index]?.answer || ''}
                                            />
                                            {formErrors.length && formErrors.filter((ele) => ele.index === index).length ? <div className="invalid-feedback-custom">
                                                This question is mandatory. Please provide a response to proceed
                                            </div> : ""}
                                        </div>
                                    ) : el.type === "MC" ? (
                                        <div className="form-group">
                                            <label>{el.question}</label>
                                            {el.options.map((ele, ind) => (
                                                <div key={ind} className="ct-radiotwo">
                                                    <input type="radio" name="multiple-choice" value={ele} onBlur={() => handleBlur(index)} id={`radio-${ind}`} defaultChecked={applicantsAnswers.filter((e) => e.answer == ele).length} onChange={(event) => handleInputChange(index, event)} />
                                                    <label htmlFor={`radio-${ind}`}>{ele}</label>
                                                </div>
                                            ))}
                                            {formErrors.length && formErrors.filter((ele) => ele.index === index).length ? <div className="invalid-feedback-custom">
                                                This question is mandatory. Please provide a response to proceed
                                            </div> : ""}
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label>{el.question}</label>
                                            {el.options.map((ele, ind) => (
                                                <div key={ind} className="ct-checkboxtwo">
                                                    <input type="checkbox" name={`chexkbox-${ind}`}
                                                        onBlur={() => handleBlur(index)} value={ele} id={`checkbox-${ind}`} defaultChecked={applicantsAnswers.filter((e) => e.answer.includes(ele)).length} onChange={(event) => handleInputChange(index, event)} />
                                                    <label htmlFor={`checkbox-${ind}`}>{ele}</label>
                                                </div>
                                            ))}
                                            {formErrors.length && formErrors.filter((ele) => ele.index === index).length ? <div className="invalid-feedback-custom">
                                                This question is mandatory. Please provide a response to proceed
                                            </div> : ""}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </fieldset>
                    </div>
                </div>
            </div>
            {navigation}
        </form>
    );
}
