import React from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import "./getin.css";
import { useRef, useState  } from "react";
import emailjs from "@emailjs/browser";



export default function Get_In_Touch(){
    const form = useRef();
    const [selectValue, setSelectValue] = useState("");
  
    const sendEmail = (e) => {
      console.log(e);
      e.preventDefault();
      console.log(selectValue);
      emailjs.sendForm("service_qhgc558", "template_j6oafvi", form.current, "nxvN8hBaUNLCIL73Y").then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
    };
    // const URL = window.location.href = "http://consforc.com/contact-us";
    return(
        <div className="ct-getintouchholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-container">
                    <div className="ct-getintouchcontentholder">
                        <div className="ct-getintouchcontent">
                            <div className="ct-setionhead">
                                <div className="ct-sectiontitle">
                                    <h2>Get in touch</h2>
                                </div>
                                <div className="ct-description">
                                    <p>Still have a question? Write to us.</p>
                                </div>

                            </div>
                        </div>
                        <div className="ct-getintouchform">
                            <form className="ct-formtheme"  ref={form} onSubmit={sendEmail} >
                            {/* <form className="ct-formtheme"> */}
                                <fieldset>
                                    <div className="form-group">
                                        <input type="text" name="full_name" placeholder="Full Name" />
                                    </div>
                                    <div className="ct-inputwidthfifty">
                                        <div className="form-group">
                                            <input type="text" name="email" placeholder="Email*" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="phone" placeholder="Mobile number" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="subject" placeholder="Subject" />
                                    </div>
                                    <div className="form-group">
                                        <textarea name="message" placeholder="Drop us a message here…"></textarea>
                                    </div>
                                    <button type="submit" className="ct-btn">Submit</button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}