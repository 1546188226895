  // import react from 'react';
import React, { useRef, useState, useEffect } from "react";
import reactDom from 'react-dom';
import Header from "../header/Header";
import Banner from "../banner/Banner";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Banner_image from "../banner/Banner_image";
import Get_In_Touch from "../contact/Get_In_Touch";
import './partner.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
// const responsive = {
//   superLargeDesktop: {
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 5  
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1
//   }
// };


export default function Staffing(){ 
    useEffect(() => {
      AOS.init(12000);
    }, []); 
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/recuriter.png")] ,
                title: 'One-stop Shop for Recruiters',
                description: 'We know your challenges, and we are ready to support you. No need to search through thousands of profiles, simply submit your requirements today to get connected.  ',
            }} />
            <Sidebar />
            <main className="ct-main">
              <div className='ct-recuriter'>
                <section className='ct-confidance ct-haslayout ct-sectionspace'>
                  <div className='ct-container'>
                    <div className='ct-recuriterholder'>
                      <div className='ct-sectionhead' data-aos="fade-left">
                        <div className='ct-sectiontitle'>
                          <h2>Hire with confidence</h2>
                        </div>
                        <div className='ct-descrition'>
                          <p>We understand that most recruiters have a small and limited network, and finding talent can be time a consuming and exhaustive process only to end up with the wrong candidates which can eventually lead to bad reputation.  </p>
                          <br/>
                          <br/>
                          <p>Becoming a talent acquisition partner with Consforc will not only save your time, but it will also put you at the forefront of companies looking for top talent. Our talent pool has hundreds of screened candidates who work with the latest tools & techniques, and follow best practices representing 40+ industries. Hire on-shore, off-shore, and near-shore resources with confidence and build the reputation that you deserve.</p>
                        </div>
                      </div>
                      <div className='ct-recuritercontent'  data-aos="fade-right">
                        <ul>
                          <li>  
                            <span>01</span>
                            <p>Sign and submit resource requirements.</p>
                          </li>
                          <li>
                            <span>02</span>
                            <p>Get introduced to Top Talent.</p>
                          </li>
                          <li>
                            <span>03</span>
                            <p>Finalize and outsource talent with confidence.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                      {/* <Carousel 
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={1000}
                        // containerClass="carousel-container"
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        // dotListClass="custom-dot-list-style"
                        // itemClass="carousel-item-padding-40-px"
                      > */}
                      
                      {/* </Carousel> */}
                {/* <section className='ct-advantges ct-haslayout ct-sectionspace'>
                  <div className="ct-containerfluid">
                    <div className='ct-sectionhead'>
                      <div className='ct-sectiontitle'  data-aos="fade-up">
                        <h2>Advantages</h2>
                      </div>
                      <div className='ct-description'  data-aos="fade-down">
                        <p>Select from our shortlisted candidates and only recommend best talent to your clients they will surely hire. </p>
                      </div>
                    </div>
                    <div className='ct-advantagesholder'>
                        <div className="ct-advantagesitem">
                          <figure>
                            <img src="/image/recruiters/Advantages/5.svg" />
                          </figure>
                          <h3>Less hassle for you</h3>
                          <p>We will take the hassle out of the recruitment process, saving you time and allowing you to focus on core business functions.</p>
                        </div>
                        <div className="ct-advantagesitem">
                          <figure>
                            <img src="/image/recruiters/Advantages/4.svg" />
                          </figure>
                          <h3>Only Hire Top Talent </h3>
                          <p>Onboard a highly skilled workforce without the cost and time investment of traditional recruitment methods.</p>
                        </div>
                        <div className="ct-advantagesitem">
                          <figure>
                            <img src="/image/recruiters/Advantages/3.svg" />
                          </figure>
                          <h3>Increase earnings by twofold</h3>
                          <p>Leverage our years of industry experience to smoothly go to market experience to go to market.</p>
                        </div>
                        <div className="ct-advantagesitem">
                          <figure>
                            <img src="/image/recruiters/Advantages/2.svg" />
                          </figure>
                          <h3>Reduce administrative overhead </h3>
                          <p>Reduce administrative overhead and free up time and resources for more strategic business activities by outsourcing your hiring.</p>
                        </div>
                        <div className="ct-advantagesitem"mmmmmmmm>
                          <figure>
                            <img src="/image/recruiters/Advantages/1.svg" />
                          </figure>
                          <h3>Meet screened candidates</h3>
                          <p>Save 90% of your time by hiring pre-vetted & technically assessed skilled professionals with us.</p>
                        </div>
                    </div>

                  </div>
                </section> */}
                <section className="ct-haslayout ct-sectionspace ct-benifatspartner">
                  <div className="ct-containerfluid">
                    <div className="ct-benifatescontentholder">
                      <div className="ct-sectionhead"  data-aos="fade-left">
                        <div className="ct-sectiontitle">
                          <h2>Benefits</h2>
                        </div>
                        <div className="ct-description">
                          <p>Gain advantage from our speedy processes and employ talent at your will. </p>
                        </div>
                        <div className="ct-benifatgrid">
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/3.svg" />
                            </span>
                            <h5>Strategic partner </h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/4.svg" />
                            </span>
                            <h5>Corporate staffing</h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/1.svg" />
                            </span>
                            <h5>Technical collaboration</h5>
                          </div>
                          <div>
                            <span>
                              <img src="/image/recruiters/Benifits/2.svg" />
                            </span>
                            <h5>Immediate Role fulfilment </h5>
                          </div>
                        </div>
                      </div>
                      <figure className="ct-benifatimageholder" data-aos="fade-right">
                        <img src="/image/recruiters/Benifits/1.jpg" />
                      </figure>
                    </div>
                  </div>
                </section>
                <Get_In_Touch />
              </div>
            </main>
            <Footer />
        </>
    )
} 