import React, {useEffect} from "react";
import reactDom from "react-dom";
import Tool from "./Tool";
import './tools.css';
// import 'animate.css';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Tools(props){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-toolsholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-containerfluid">
                    <div className="ct-sectionhead text-center"  data-aos="down">
                        <div className="ct-sectiontitle">
                            <h2>{props.section.title}</h2>
                        </div>
                        <div className="ct-description">
                            <p>{props.section.description}</p>
                        </div>
                    </div>
                    <div className="ct-toolscontent">
                        <div className="ct-tools" data-aos="fade-left">
                            <Tool  data-aos="fade-left" tool={{
                                icon:[("image/landing-page/tools/8.jpg")],
                                title:'Cloud',
                                description:'Deploy your applications using latest cloud platforms. Whether you wish to go with GCP or AWS, we have the expertise to support your application requirements ',
                            }} />
                            <Tool  data-aos="fade-right" tool={{
                                icon:[("image/landing-page/tools/4.jpg")],
                                title:'Quality Assurance',
                                description:'Testing methodologies which make products robust and reliable. We leverage the latest tools and techniques to test software to ensure the meet high quality standards. Selenium, Cypress, Appium, testcomplete, Ranorex, Postman, Apache Jmeter, CodeScene',
                            }} />
                            <Tool  data-aos="fade-left" tool={{
                                icon:[("image/landing-page/tools/10.jpg")],
                                title:'AI/Ml',
                                description:'Superior algorithms that make data usable while using GPT-3 to support your AI needs. Build products using the latest AI models to generate content that add value.',
                            }} />
                            <Tool  data-aos="fade-right" tool={{
                                icon:[("image/landing-page/tools/6.jpg")],
                                title:'Code Management',
                                description:'Managed repositories so you never lose a single line of code. Github, Bitbucket, GitLab.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/1.jpg")],
                                title:'Big Data',
                                description:'Use latest frameworks to simplify massive datasets. Apache Hadoop, Atlas.ti, HPCC, apache Storm,  Qubole, Cassandra, Statwing, CouchDB, Pentaho, Flink, Cloudera,',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/9.jpg")],
                                title:'Database',
                                description:'Schemas that flawlessly support relational data. Launch your products with the database that best meets your application requirements. MySQL, PostgreSQL, MongoDB, Redis.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/11.jpg")],
                                title:'Mobile Applications',
                                description:'Make smartphones more powerful by developing world-changing mobile apps using Swift, Kotlin, React native, and Flutter. We specialize in building applications.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/12.jpg")],
                                title:'Design',
                                description:'Intelligently crafted UI/UX designs to engage diverse audience with your products so that you can maximize the number of users and add new features on the go.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/2.jpg")],
                                title:'Frontend',
                                description:'Develop enticing user interfaces using the frameworks of your choice. Based on unique use cases, we will ensure that your GUI is ready to meet industry expectations.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/3.jpg")],
                                title:'Backend',
                                description:'Handle complex scenarios by leveraging the power of programming. We understand the value every programming language adds when it comes to product development.',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/5.jpg")],
                                title:'Devops',
                                description:'Manage cloud infrastructure with state-of-the-art cloud services. Docker, git, Kubernetes, Jenkins, Nagios, ',
                            }} />
                            <Tool tool={{
                                icon:[("image/landing-page/tools/7.jpg")],
                                title:'Project Management',
                                description:'PMO which supports all project needs from initiation to delivery. Our project management experts will ensure that your projects stay on track and meet deadlines without.',
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};