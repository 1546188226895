import React, {useEffect} from "react";
import BannerCategorie from "./BannerCategorie";
import {Carousel} from 'react-bootstrap';
import './banner.css';
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";



export default function Banner(){
  useEffect(() => {
    AOS.init(12000);
  }, []);  
    return(
        <div className="ct-banner">
          <Carousel>
            <Carousel.Item>
              <figure>
                <img
                  className="d-block w-100"
                  src="image/banner/1.jpg"
                  alt="First slide"
                />
                <figcaption>
                  <div className="ct-bannertitle">
                    <h1>Virtual Teams</h1>
                  </div>
                  <div className="ct-bannerdescription">
                    <p>Reduce overhead by hiring experienced team members worldwide – automatically fuel your ecosystem while focusing on growth </p>
                  </div>
                </figcaption>
              </figure>
            </Carousel.Item>
            <Carousel.Item>
              <figure>
                <img
                  className="d-block w-100"
                  src="image/banner/2.jpg"
                  alt="First slide"
                />
                <figcaption>
                  <div className="ct-bannertitle">
                    <h1>STEER AHEAD</h1>
                  </div>
                  <div className="ct-bannerdescription">
                    <p>Our teams continuously plan so you can focus on what matters the most. </p>
                  </div>
                </figcaption>
              </figure>
            </Carousel.Item>
            <Carousel.Item>
              <figure>
                <img
                  className="d-block w-100"
                  src="image/banner/3.jpg"
                  alt="First slide"
                />
                <figcaption>
                  <div className="ct-bannertitle">
                    <h1>EMBRACE CHANGE</h1>
                  </div>
                  <div className="ct-bannerdescription">
                    <p>Especially when it is more efficient </p>
                  </div>
                </figcaption>
              </figure>
            </Carousel.Item>
            <Carousel.Item>
              <figure>
                <img
                  className="d-block w-100"
                  src="image/banner/4.jpg"
                  alt="First slide"
                />
                <figcaption>
                  <div className="ct-bannertitle">
                    <h1>REALIZE THE POTENTIAL OF TECHNOLOGY </h1>
                  </div>
                  <div className="ct-bannerdescription">
                    <p>Fuel the world in ways which were not imaginable before </p>
                  </div>
                </figcaption>
              </figure>
            </Carousel.Item>
            {/* <Carousel.Item>
              <figure>
                <img
                  className="d-block w-100"
                  src="image/banner/1.jpg"
                  alt="First slide"
                />
                <figcaption>
                  <div className="ct-bannertitle">
                    <h1>Our Core Vision & Strateg</h1>
                  </div>
                  <div class="ct-bannerdescription">
                    <p>Helping businesses to find best solutions over the years.</p>
                  </div>
                </figcaption>
              </figure>
            </Carousel.Item> */}
          </Carousel>
          <div className="ct-bannertitlearea" data-aos="fade-right">
            <ul>
              <li>
                <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">Book a Meeting</a>
              </li>
              <li>
                <Link to="/contact-us" className="ct-btngetstarted">
                  <span>Get Started</span>
                  <i className="icon-arrow-right2"></i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="ct-bannercategories" data-aos="fade-right">
            <ul>
              <BannerCategorie categorie={{
                  title: 'Experienced Teams',
                  description: 'People who are trained in your domain.',
              }}/>
              <BannerCategorie categorie={{
                  title: 'Industry Expertise',
                  description: 'Only hire industry experts.',
              }}/>
              <BannerCategorie categorie={{
                  title: 'Premium Services ',
                  description: 'Pick top talent at competitive hourly rates.',
              }}/>
              <BannerCategorie categorie={{
                  title: 'Partner in Trust',
                  description: 'Develop ideas with peace of mind.',
              }}/>
              <BannerCategorie categorie={{
                  title: 'Reliability ',
                  description: 'Most reliable partner in meeting delivery.',
              }}/>
              <BannerCategorie categorie={{
                  title: 'Improved ROI ',
                  description: 'High revenues, low expenses.',
              }}/>
            </ul>
          </div>
        </div>
    )
}