import React, {useEffect} from "react";
import reactDom from "react-dom";
import OfferContent from './OfferContent';
// import Fade from 'react-reveal/Fade';
import './offer.css';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Offer(){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-offerholder">
            <div className="ct-containerfluid">
                <div className="ct-sectionspace ct-haslayout">
                    <div className="ct-offerholder">
                        {/* <Fade> */}
                            <div className="ct-offerheadingarea">
                                <div className="ct-sectionhead" data-aos="fade-down">
                                    <div className="ct-sectiontitle">
                                        <h2>What We Offer</h2>
                                    </div>
                                    <div className="ct-description">
                                        <p>Countless opportunities for you to grow exponentially </p>
                                    </div>
                                </div>
                            </div>
                        {/* </Fade> */}
                        <div className="ct-offercontent">
                            <div className="ct-offergrid" data-aos="fade-right">
                                <OfferContent offer={{
                                    icon: [("icon-Frame-3106")],
                                    subtitle: 'Consulting',
                                    description: 'Leverage our years of industry experience to smoothly go to market.',
                                }}/>
                                <OfferContent offer={{
                                    icon: [("icon-chevron-down-4")],
                                    subtitle: 'Implementation',
                                    description: 'Develop powerful business applications to compete with the very best.',
                                }}/>
                                <OfferContent offer={{
                                    icon: [("icon-Frame-3107")],
                                    subtitle: 'Managed Services',
                                    description: 'Focus on expanding the business while our dedicated teams manage your infrastructure.',
                                }}/>
                                <OfferContent offer={{
                                    icon: [("icon-Frame-3108")],
                                    subtitle: 'Staffing',
                                    description: 'Connect with top talent to drive your business forward.',
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}