import React from "react";
import reactDom from "react-dom";
import { openPopupWidget } from "react-calendly";
import './clients.css'

export default function Clients(){
    const openSideBarHandler = () => {
        const el = document.getElementById('root');
        if(el){
            el.classList.toggle("showrecruiters");
        }
    }

    return(
        <div className="ct-clientsholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-containerfluid">
                    <div className="ct-clientscontentholder">
                        <div className="ct-sectionhead text-center">
                            <div className="ct-sectiontitle">
                                <h2 className="ct-pone">One-tenth the price of an office, ten times the value.</h2>
                                <h2 className="ct-ptwo">We speak your language </h2>
                            </div>
                            <div className="ct-description">
                                <p className="ct-pone">Select from monthly plans that’ll take you from startups to the world’s largest companies.</p>
                                <p className="ct-ptwo">Connect with top talent and easily fulfil your contractual obligations. </p>
                            </div>
                        </div>
                        <div className="ct-togglebuttonform">
                            <form className="ct-formtheme">
                                <fieldset>
                                    <div className="ct-tooglebutton">
                                        
                                        <input type="checkbox" id="toggle" onChange={openSideBarHandler} />
                                        <label for="toggle">
                                            <span>Clients</span>
                                            <span>Recruiters</span>
                                        </label>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div className="ct-clientsgridholder ct-haslayout">
                            <div className="ct-clientsgrid">
                                <div className="ct-client">
                                    <div className="ct-clienticon">
                                        <i className="icon-user1"></i>
                                    </div>
                                    <div className="ct-clienttitle">
                                        <h3>Small Business</h3>
                                    </div>
                                    <div className="ct-description">
                                        <p>Whether you wish to start a business or grow your start up, our teams can help you get established. </p>
                                    </div>
                                    <div className="ct-btnschedule">
                                        <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">
                                            <span>Book a Meeting</span>
                                            <i className="icon-arrow-right2"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="ct-client">
                                    <div className="ct-clienticon">
                                        <i className="icon-users1"></i>
                                    </div>
                                    <div className="ct-clienttitle">
                                        <h3>Enterprise</h3>
                                    </div>
                                    <div className="ct-description">
                                        <p>Leverage our deep industry experience to increase your ROI and deliver projects which exceed expectations.</p>
                                    </div>
                                    <div className="ct-btnschedule">
                                        <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">
                                            <span>Book a Meeting</span>
                                            <i className="icon-arrow-right2"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="ct-client">
                                    <span className="ct-iconstar"><i className="icon-star-full"></i></span>
                                    <div className="ct-clienticon">
                                        <i className="icon-users1"></i>
                                    </div>
                                    <div className="ct-clienttitle">
                                        <h3>Premium Services</h3>
                                    </div>
                                    <div className="ct-description">
                                        <p>Enjoy our premium business services to manage your business while you focus on expanding your areas of delivery.</p>
                                    </div>
                                    <div className="ct-btnschedule">
                                        <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">
                                            <span>Book a Meeting</span>
                                            <i className="icon-arrow-right2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-recuritersholder ct-haslayout">
                            <div className="ct-recruitersgrid ct-clientsgrid">
                                <div className="ct-client">
                                    <div className="ct-clienticon">
                                        <i className="icon-user1"></i>
                                    </div>
                                    <div className="ct-clienttitle">
                                        <h3>Subscription</h3>
                                    </div>
                                    <div className="ct-description">
                                        <p>Pay a monthly subscription to stay in touch with top talent.</p>
                                    </div>
                                    <div className="ct-btnschedule">
                                        <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">
                                            <span>Book a Meeting</span>
                                            <i className="icon-arrow-right2"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="ct-client">
                                    <div className="ct-clienticon">
                                        <i className="icon-users1"></i>
                                    </div>
                                    <div className="ct-clienttitle">
                                        <h3>Pay as you go</h3>
                                    </div>
                                    <div className="ct-description">
                                        <p>Find the best people when you need them.</p>
                                    </div>
                                    <div className="ct-btnschedule">
                                        <a href="https://calendly.com/consforcllc/company-overview" className="ct-btn" target="_blank">
                                            <span>Book a Meeting</span>
                                            <i className="icon-arrow-right2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}