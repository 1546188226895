import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { Outlet, Navigate } from "react-router-dom";
import moment from "moment";
import { parseJwt } from "../utilities/common";

export default function PrivateRoute() {
  const { getData } = useLocalStorage();
  const parsedData = JSON.parse(getData("_token"));
  const decodedJwt =
    parsedData?.data?.token && parseJwt(parsedData?.data?.token).exp;

  return parsedData?.data?.token &&
    moment(decodedJwt * 1000).format() > moment().format() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}
