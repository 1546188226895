import React from "react";
import reactDom from "react-dom";

export default function OfferContent(props){
    return(
        <div className="ct-offer" data-aos="fade-right" >
            <div className="ct-offertitle">
                <i className={props.offer.icon}></i>
            </div>
            <div className="ct-offersubtitle">
                <h4>{props.offer.subtitle}</h4>
            </div>
            <div className="ct-offerdescription">
                <p>{props.offer.description}</p>
            </div>
        </div>
    );
};