import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import Reason from "./Reason";
import Delete from "./Delete";
import { toast } from "react-toastify";
import api from "../axiosInstance";
import { useMutation } from "react-query";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export default function Delete_account() {
    const [show, setShow] = useState(false);
    const [isOtherSelected, setIsOtherSelected] = useState(false)
    const { removeSession } = useLocalStorage()
    const [reason, setReason] = useState("")
    const [step, setStep] = useState(0);
    const navigate = useNavigate()

    const handleClose = () => {
        setStep(0)
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const renderStep = () => {
        switch (step) {
            case 0:
                return <Reason
                    step={step}
                    setStep={setStep}
                    isOtherSelected={isOtherSelected}
                    setIsOtherSelected={setIsOtherSelected}
                    reason={reason}
                    setReason={setReason}
                />
            case 1:
                return <Delete
                    step={step}
                    setStep={setStep}
                />
            default:
                return null;
        }
    };


    const updatePasswordApi = async (data) => {
        return await api.delete("/applicants/delete-account", data)
    }

    const { mutate, isLoading } = useMutation(updatePasswordApi, {
        onSuccess: () => {
            removeSession()
            navigate("/")
            // toast.success("password updated successfully")
            handleClose()
        },
        onError: (data) => {
            toast.error(data.response.data.message)
        }
    });


    const handleDeleteAcc = (e) => {
        e.preventDefault()
        if (reason === '') {
            toast.error("reson is required")
        } else {
            mutate({ reason })
        }
    }

    return (

        <>
            <button className="ct-btn" onClick={handleShow}>Delete Account</button>

            <Modal show={show} onHide={handleClose} className="ct-changepasswordmodal">
                <div className="ct-modalform">
                    <form className="ct-formtheme" onSubmit={handleDeleteAcc}>
                        <fieldset>
                            <div className="ct-modaltitle">
                                <div>
                                    <h2>Deleting account</h2>
                                </div>
                            </div>
                            {renderStep()}
                            <div className="ct-btnholder">
                                <button className="ct-btn ct-btncancel" type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                                {step === 1 &&
                                    <button className="ct-btn" type="button" onClick={(e) => {
                                        e.preventDefault()
                                        setStep(step - 1)
                                    }}>
                                        Previous
                                    </button>
                                }
                                {step === 1 ?

                                    <button className="ct-btn" disabled={isLoading} type="submit" style={{ background: "#E03E3E" }}>
                                        {isLoading ? <Spinner size="sm" /> : "Delete"}
                                    </button> :
                                    <button className="ct-btn" type="button" onClick={(e) => {
                                        e.preventDefault()
                                        setStep(step + 1)
                                    }}>
                                        Next
                                    </button>
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Modal>
        </>
    );
}