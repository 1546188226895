import React, {useEffect} from "react";
import reactDom from "react-dom";
import Benefit from "./Benefit";
import './benefits.css';
import Proposal from "../../header/Proposal";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Benefits(){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-benefitsholder">
            <div className="ct-haslayout ct-sectionspace">
                <div className="ct-containerfluid">
                    <div className="ct-benefitscontent">
                        <div className="ct-sectionhead">
                            <div className="ct-sectiontitle">
                                <h2 data-aos="fade-right">Benefits</h2>
                            </div>
                            <div className="ct-description">
                                <p data-aos="fade-left">Get advantage of our easy to use portals without a charge to manage your business on day to day basis. </p>
                            </div>
                            <div className="ct-btnpurposel" data-aos="fade-up">
                                <Proposal />
                            </div>
                        </div>
                        <div className="ct-benefitscontentholder">
                            <figure className="ct-bgbenefitimage" data-aos="fade-right">
                                <img src="image/benefits.png" alt="benefit.png" />
                            </figure>
                            {/* <div className="ct-benefitscontent">
                                <div className="ct-benefitsgrid">
                                    <Benefit benefit={{
                                        title: 'Companies',
                                        description:'Access portals to manage your business better than excel sheets. ',
                                        list1:'Manage Unlimited Projects',
                                        list2:'Control Virtual Teams',
                                        list3:'Handle Change Requests',
                                    }} />
                                    <Benefit benefit={{
                                        title: 'Recruiter',
                                        description: 'End your hunt for good. Manage talent worldwide without any hassle.',
                                        list1: 'Top Talent',
                                        list2: 'Job Bank',
                                        list3: 'Matched Recommendations',
                                    }} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}