import React from "react";
import { useState } from "react";
import JobDetail from "./Job_Detail";
import BasicDetails from "./Basic_Details";
import WorkEducation from "./Work_Education";
import ApplicationPreview from "./Application_Preview";
import InitialScreening from "./Initial_Screening";
import moment from "moment";
import { useFormik } from 'formik';
import { basicDetailSchema } from "./schemas/basic-detail-schema";
import { workExperienceSchema } from "./schemas/work-experience-schema";
import NavigationButtons from "./navigation";
import Reffer from "./Reffer";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { referralSchema } from "./schemas/referrer-schema";
import { Spinner } from "react-bootstrap";
import api from "../../../axiosInstance";

export default function JobPreview({ data, setActive, refetch: refetchJOB }) {
    const [step, setStep] = useState(0);
    const [applyAs, setApplyAs] = useState("")
    const [guestData, setGuestData] = useState({})
    const [applicantsAnswers, setApplicantsAnswers] = useState(data?.questions.map((ele) => ({ type: ele.type, options: ele.options, answer: ele.type === "TA" || ele.type === "MC" ? "" : [] })));
    const [file, setFile] = useState("")

    const submitReferral = async (data) => {
        return await api.post("/applicants/referrer", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const { mutate, isLoading } = useMutation(submitReferral, {
        onSuccess: () => {
            toast.success("referral submit successfully!")
            setStep(0)
            setApplyAs("")
            setActive("false")
            setFile("")
            referral.resetForm()
        },
        onError: (data) => {
            if (data.response.status === 403) {
                toast.error("token expired try to login again")
            }
            toast.error(data.response.data.message)
        }
    });


    const referral = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            linkedinURL: "",
            r_firstName: "",
            r_lastName: "",
            r_email: "",
            r_phoneNumber: "",
            pdf: {},
            country: "",
            state: "",
            city: "",
            pdfURL: "",
            experienceLevel: ""
        },
        validationSchema: referralSchema,
        onSubmit: (values) => {
            const formData = new FormData()
            formData.append('file', values.pdfURL)
            formData.append('data', JSON.stringify({ ...values, stage: data?.pipeLine.stages[0], jobId: data?._id }))
            mutate(formData)
        },
    })

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            linkedinURL: "",
            profileSummary: "",
            country: "",
            state: "",
            city: "",
            pdfURL: "",
            experienceLevel: ""
        },
        validationSchema: basicDetailSchema,
        onSubmit: (values) => {
            setGuestData((prev) => ({
                ...prev, basicDetail: values
            }))
            setStep(step + 1)
        },
    });

    const workExperienceFormik = useFormik({
        initialValues: {
            educations: [{ institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }],
            certifications: [{ institute: "", startDate: "", endDate: "" }],
            experiences: [{ jobRole: "", company: "", startDate: "", endDate: "", details: "" }],
            tools: []
        },
        validationSchema: workExperienceSchema,
        onSubmit: (values) => {
            setGuestData((prev) => ({
                ...prev, values
            }))
            setStep(step + 1)
        },
    });

    const [formErrors, setFormErrors] = useState([]);

    const handleBlur = (index) => {
        const errors = [...formErrors];
        if (!applicantsAnswers[index]?.answer || applicantsAnswers[index]?.answer.length === 0) {
            errors.push({ error: "This question is required.", index: index });
        } else {
            const errorIndex = errors.findIndex((error) => error.index === index);
            if (errorIndex !== -1) {
                errors.splice(errorIndex, 1);
            }
        }
        setFormErrors(errors);
    };

    const handleInputChange = (index, event) => {
        const { value, type, checked } = event.target;
        const updatedAnswers = [...applicantsAnswers];
        const question = data.questions[index];

        if (type === "checkbox") {
            const selectedOptions = updatedAnswers[index]?.answer ? [...updatedAnswers[index].answer] : [];

            if (checked) {
                selectedOptions.push(value);
                if (formErrors.filter((el) => el.index === index).length) {
                    setFormErrors(prevErrors => prevErrors.filter(error => error.index !== index));
                }
            } else {
                const valueIndex = selectedOptions.indexOf(value);
                if (valueIndex !== -1) {
                    selectedOptions.splice(valueIndex, 1);
                }
                if (selectedOptions.length === 0) {
                    setFormErrors([...formErrors, { error: "This question is required.", index: index }]);
                }
            }

            updatedAnswers[index] = {
                type: "CB",
                question: question.question,
                answer: selectedOptions,
                options: question.options
            };
        } else if (type === "radio") {
            updatedAnswers[index] = {
                type: "MC",
                question: question.question,
                answer: value,
                options: question.options
            };
            if (formErrors.filter((el) => el.index === index).length) {
                setFormErrors(prevErrors => prevErrors.filter(error => error.index !== index));
            }
        } else {
            updatedAnswers[index] = {
                type: "TA",
                question: question.question,
                answer: value
            };
            if (formErrors.filter((el) => el.index === index).length) {
                setFormErrors(prevErrors => prevErrors.filter(error => error.index !== index));
            }
            if (updatedAnswers[index].answer === "") {
                setFormErrors([...formErrors, { error: "This question is required.", index: index }]);
            }
        }

        setApplicantsAnswers(updatedAnswers);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = [];
        applicantsAnswers.forEach((answer, index) => {
            if (!answer || !answer.answer || answer.answer.length === 0) {
                errors.push({ error: "This question is required.", index: index });
            }
        });

        setFormErrors(errors);
        if (errors.length === 0) {
            setGuestData((prev) => ({
                ...prev, applicantsAnswers,
            }))
            setStep(step + 1)
        }
    };

    const { getData } = useLocalStorage();

    const parsedData = JSON.parse(getData("_token"));

    const getCurrentApplicantApi = async (data) => {
        return await api.get(`/applicants/current-applicant/${data.queryKey[1]}`)
    }
    const { refetch } = useQuery(
        ["get-current-applicant", parsedData?.data?.user?._id],
        getCurrentApplicantApi,
        {
            onSuccess: (data) => {
                setGuestData((prev) => ({ ...prev, pdfURL: data?.data.user.pdfURL ? data?.data.user.pdfURL : "" }))
                // console.log(applyAs);
                formik.setValues({
                    firstName: data.data.user.firstName,
                    lastName: data.data.user.lastName,
                    email: data.data.user.email,
                    phoneNumber: data.data.user.phoneNumber,
                    linkedinURL: data.data.user.linkedinURL,
                    profileSummary: data.data.user.profileSummary,
                    country: JSON.stringify(data.data.user.country),
                    state: JSON.stringify(data.data.user.state),
                    city: data.data.user.city,
                    pdfURL: data.data.user.pdfURL ? data.data.user.pdfURL : "",
                    experienceLevel: data.data.user.experienceLevel
                })

                workExperienceFormik.setValues({
                    educations: data.data.user.educations.length ? data.data.user.educations : [{ institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }],
                    certifications: data.data.user.certifications.length ? data.data.user.certifications : [{ institute: "", startDate: "", endDate: "" }],
                    experiences: data.data.user.experiences.length ? data.data.user.experiences : [{ jobRole: "", company: "", startDate: "", endDate: "", details: "" }],
                    tools: data.data.user.tools
                })

            },
            refetchOnWindowFocus: false,
            enabled: parsedData?.data?.user ? true : false
        },
    );

    const renderStep = () => {
        switch (step) {
            case 0:
                return <BasicDetails
                    formik={formik}
                    navigation={
                        <NavigationButtons
                            step={step}
                            setStep={setStep}
                            setApplyAs={setApplyAs}
                            setActive={setActive}
                            formik={formik} />}
                    setFile={setFile}
                />
            case 1:
                return <WorkEducation
                    formik={workExperienceFormik}
                    navigation={
                        <NavigationButtons
                            step={step}
                            setStep={setStep}
                            setApplyAs={setApplyAs}
                            setActive={setActive}
                            formik={workExperienceFormik} />}
                />
            case 2:
                return <InitialScreening data={data} handleSubmit={handleSubmit} applicantsAnswers={applicantsAnswers} handleInputChange={handleInputChange} handleBlur={handleBlur} formErrors={formErrors} navigation={
                    <NavigationButtons
                        step={step}
                        setStep={setStep}
                        setApplyAs={setApplyAs}
                        setActive={setActive}
                        formik={workExperienceFormik} />} />

            case 3:
                return <> <ApplicationPreview data={guestData} />
                    <NavigationButtons step={step} setStep={setStep} setApplyAs={setApplyAs} setActive={setActive} formik={formik} jobId={data?._id} data={guestData} stage={data?.pipeLine.stages[0]} applyAs={applyAs} userId={parsedData?.data?.user._id} refetch={refetch} file={file} setFile={setFile} workExperienceFormik={workExperienceFormik} refetchJOB={refetchJOB} />
                </>
            default:
                return null;
        }
    };

    const renderForm = () => {
        switch (applyAs) {
            case "quick":
                return renderStep()
            case "referral":
                return <Reffer formik={referral} navigation={<div className="ct-btnholder">
                    <button disabled={isLoading} className="ct-btn" type="submit">{isLoading ? <Spinner size="sm" /> : "Submit Referral"}</button>
                </div>} setFile={setFile} />
            case "guest":
                return renderStep()
            default:
                return <JobDetail
                    data={data}
                />
        }
    }

    const handleQuickApply = () => {
        refetch()
        setApplyAs("quick")
    }

    return (

        <>
            <div className="ct-jobpreview">
                <div className="ct-createjobhead">
                    <div className="ct-jobtitle">
                        <h2>{data?.jobTitle}</h2>
                    </div>
                    <div className="ct-dateid">
                        <span>Posted on: {moment(data?.createdAt).format("DD MMM, YYYY")}</span>
                        <span>Job ID: {data?.jobID}</span>
                    </div>
                </div>
                {renderForm()}
                <div className="ct-btnholder">
                    <button className="ct-btn ct-btncancel" disabled={data?.applicants.includes(parsedData?.data?.user._id)} onClick={() => parsedData?.data ? handleQuickApply() : toast.info("Login first to proceed to quick apply")}>{data?.applicants.includes(parsedData?.data.user._id) ? "Already Applied" : "Quick Apply"}</button>
                    <button className="ct-btn" onClick={() => setApplyAs("referral")}>Submit referral</button>
                    <button className="ct-btn ct-btncancel" onClick={() => {
                        formik.resetForm()
                        workExperienceFormik.resetForm()
                        setApplyAs("guest")
                    }
                    }
                    >Apply as guest</button>
                </div>
            </div>
        </>
    );
}