import Accordion from 'react-bootstrap/Accordion';
import { useQuery } from 'react-query';
import api from '../../../axiosInstance';

const jobTypesData = ["Full Time", "Part Time", "Contract", "Temporary", "Volunteer", "Internship"]
const experienceLevelData = ["Entry-level", "Junior (1-2 years)", "Mid (2-5 years)", "Senior (5-10 years)", "Expert (15+ years)"]
const workPlaceTypeData = ["Remote", "Hybrid", "On-Site"]

export default function JobFilter({ filterSetter, idValue }) {

    const getJobsLocationsListApi = async () => {
        return await api.get("/common/jobs")
    }
    const { data: locations } = useQuery(
        "get-jobs-locations",
        getJobsLocationsListApi,
        {
            refetchOnWindowFocus: false,
        },
    );

    return (
        <div className='ct-filter'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Job type</Accordion.Header>
                    <Accordion.Body>
                        {jobTypesData.map((el, index) => (
                            <div className='form-group' key={index}>
                                <div className='ct-checkboxtwo'>
                                    <input type='checkbox' id={`checkbox-${idValue}-${index}`} value={el} onChange={() => filterSetter("jobType", el)} />
                                    <label htmlFor={`checkbox-${idValue}-${index}`}>{el}</label>
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Experience level</Accordion.Header>
                    <Accordion.Body>
                        {experienceLevelData.map((el, index) => (
                            <div className='form-group' key={index}>
                                <div className='ct-checkboxtwo'>
                                    <input type='checkbox' id={`experience-${idValue}-${index}`} onChange={() => filterSetter("experienceLevel", el)} />
                                    <label htmlFor={`experience-${idValue}-${index}`}>{el}</label>
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Workplace type</Accordion.Header>
                    <Accordion.Body>
                        {workPlaceTypeData.map((el, index) => (
                            <div className='form-group' key={index}>
                                <div className='ct-checkboxtwo'>
                                    <input type='checkbox' id={`workplace-${idValue}-${index}`} onChange={() => filterSetter("workPlaceType", el)} />
                                    <label htmlFor={`workplace-${idValue}-${index}`}>{el}</label>
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Salary range</Accordion.Header>
                    <Accordion.Body>
                        <div className='ct-inputwidthfifty'>
                            <div className='form-group'>
                                <label>Min</label>
                                <input type='number' name='min' placeholder='Min' />
                            </div>
                            <div className='form-group'>
                                <label>Max</label>
                                <input type='number' name='Max' placeholder='Max' />
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Location</Accordion.Header>
                    <Accordion.Body>
                        {locations?.data?.locations?.map((el, index) => (
                            <div className='form-group' key={index}>
                                <div className='ct-checkboxtwo'>
                                    <input type='checkbox' id={`location-${idValue}-${index}`} onChange={() => filterSetter("location", el)} />
                                    <label htmlFor={`location-${idValue}-${index}`}>{el}</label>
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>State/Province</Accordion.Header>
                    <Accordion.Body>
                        <div className='form-group'>
                            <div className='ct-checkboxtwo'>
                                <input type='checkbox' name='checkbox' id='Punjab' />
                                <label htmlFor='Punjab'>Punjab (4)</label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className='ct-checkboxtwo'>
                                <input type='checkbox' name='checkbox' id='Massachusetts' />
                                <label htmlFor='Massachusetts'>Massachusetts (1)</label>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}
                {/* <Accordion.Item eventKey="6">
                    <Accordion.Header>City</Accordion.Header>
                    <Accordion.Body>
                        <div className='form-group'>
                            <div className='ct-checkboxtwo'>
                                <input type='checkbox' name='checkbox' id='Lahore' />
                                <label htmlFor='Lahore'>Lahore (3)</label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <div className='ct-checkboxtwo'>
                                <input type='checkbox' name='checkbox' id='Islamabad' />
                                <label htmlFor='Islamabad'>Islamabad (1)</label>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        </div>
    );
}