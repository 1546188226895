// import react from 'react';
import React, {useEffect} from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Banner_image from "../banner/Banner_image";
import Get_In_Touch from "../contact/Get_In_Touch";
// import './partner.css';
import './people.css'
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function People(){ 
  useEffect(() => {
    AOS.init(2000);
  }, []); 
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/people.jpg")] ,
                title: 'Meet Our Team ',
                description: 'Helping businesses to find best solutions over the years.'
            }} />
            <Sidebar />
            <main className="ct-main">
              <div className='ct-people'>
                <section className="ct-people ct-haslayout ct-sectionspace">
                  <div className="ct-container">
                  <div className="ct-sectionhead ct-pb-40">
                    <div className="ct-sectiontitle">
                      <h2 className="text-center">Leadership</h2>
                    </div>
                  </div>
                    <div className="ct-peoplegridceowner ct-pb-40">
                      <div className="ct-peoplegriditem">
                        <figure className="ct-peopleimg">
                          <img src="image/jwaria.jpg" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                          <div className="ct-teamusername">
                            <h3>Jwaria Aizaz </h3>
                            <span>Vice President of Customer Experience</span>
                          </div>
                          <div className="ct-description">
                            <p>A strategic leader responsible for overseeing all aspects of customer interactions. Driving customer satisfaction, loyalty, and advocacy through innovative initiatives and exceptional service delivery.</p>
                          </div>
                        </div>
                      </div>
                      <div className="ct-peoplegriditem">
                        <figure className="ct-peopleimg">
                          <img src="image/002.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                        <div className="ct-teamusername">
                          <h3>Khurram Zahid</h3>
                          <span>Director Client Services</span>
                        </div>
                          <div className="ct-description">
                            <p>Talented professional with years of client services and business management experience. Equipped with expertise in Computing Science and Business Administration, bringing a unique blend of technical and business acumen to drive success. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ct-peoplegridceowner">
                      <div className="ct-peoplegriditem ct-teammember">
                        <figure className="ct-peopleimg">
                          <img src="image/junaid.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                          <div className="ct-teamusername">
                            <h3>Junaid Hassan</h3>
                            <span>IT Talent Manager</span>
                          </div>
                          <div className="ct-description">
                            <p>Our dedicated HR specialist takes pride in cultivating a talented workforce and fostering a positive work environment. Committed to implementing strategic HR initiatives, talent acquisition, training, and employee development to empower organization's most valuable asset – our people.</p>
                          </div>
                        </div>
                      </div>
                      <div className="ct-peoplegriditem ct-teammember">
                        <figure className="ct-peopleimg">
                          <img src="image/004.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                        <div className="ct-teamusername">
                          <h3>Muhammad Arshad</h3>
                          <span>Accounts & Finance</span>
                        </div>
                          <div className="ct-description">
                            <p>A financial expert equipped with a wealth of knowledge in financial analysis, budgeting, and forecasting. Their insights and data-driven approach will provide the foundation for making informed decisions, optimizing resources, and achieving your financial objectives.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ct-peoplegriditem ct-teammember">
                        <figure className="ct-peopleimg">
                          <img src="image/005.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                        <div className="ct-teamusername">
                          <h3>Muhammad Akbar</h3>
                          <span>IT Support</span>
                        </div>
                          <div className="ct-description">
                            <p>Dedicated IT support expert with years of experience in security, troubleshooting, network maintenance, and system optimization. Adept at resolving technical challenges promptly and efficiently.                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ct-peoplegriditem ct-teammember">
                        <figure className="ct-peopleimg">
                          <img src="image/006.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                        <div className="ct-teamusername">
                          <h3>Hisham Talib Qureshi</h3>
                          <span>Design</span>
                        </div>
                          <div className="ct-description">
                            <p>A skilled UX Designer and Photographer with an engineering background. Excelling in crafting functional and visually appealing designs for mobile & web applications. Always committed to user-centric solutions, Hisham leads all UI/UX initiatives , ensuring high-quality collaboration and industry design standards.</p>
                          </div>
                        </div>
                      </div>
                      <div className="ct-peoplegriditem ct-teammember">
                        <figure className="ct-peopleimg">
                          <img src="image/008.png" alt="team image" />
                        </figure>
                        <div className="ct-teamusernameroletext">
                        <div className="ct-teamusername">
                          <h3>Haider Ali</h3>
                          <span>Artificial Intelligence & Machine Learning</span>
                        </div>
                          <div className="ct-description">
                            <p>A seasoned professional who specializes in creating, developing, and implementing AI systems and technologies. Passionate about conducting research to identify new AI techniques and applications, developing and testing AI algorithms and models.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* <div className="ct-ourlocationholder ct-stafingsuccessstory">
                    <div className="ct-sectionspace ct-haslayout">
                        <div className="ct-container">
                            <div className="ct-sectionhead text-center"  data-aos="fade-down">
                                <div className="ct-sectiontitle">
                                    <h2>Our success stories</h2>
                                </div>
                            </div>
                            <div className="ct-ourlocationcontentholder">
                                <div className="ct-ourlocations">
                                    <div className="ct-ourlocation"  data-aos="fade-left">
                                        <figure>
                                            <img src="image/1.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 1</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-up">
                                        <figure>
                                            <img src="image/2.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3> Success stories 2 </h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/3.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 3</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <Get_In_Touch /> */}
              </div>
            </main>
            <Footer />
        </>
    )
}