import React from "react";
import "./header.css";
import Dropdown from 'react-bootstrap/Dropdown';
// import Flag from "../../../public/images/png/us.png";
import Proposal from "./Proposal";
// import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import api from "../../axiosInstance";

export default function Header() {
	// const { getData, removeSession } = useLocalStorage();
	// const parsedData = JSON.parse(getData("_token"));
	// const navigate = useNavigate()
	// const openSideBarHandler = () => {
	//     const el = document.getElementById('root');
	//     if(el){
	//         el.classList.toggle("showSideBar");
	//     }
	// }
	// const logout = async () => {
    //     try {
	// 		await api.post("/admin/logout")
    //         removeSession();
	// 		navigate("/login")
    //         toast.success("Logout successfully!")
    //     }
    //     catch (err) { }
    // }

	return (
		<header className="ct-header">
			{/* <button className="ct-btnmenu" onClick={openSideBarHandler}>
				<i className="icon-menu1"></i>
			</button> */}
			<strong className="ct-logo">
				<Link to="/">
					<img src="image/logo.svg" alt="logo" />
				</Link>
			</strong>
			<div className="ct-headerrightarea">
				<ul>
					{/* <li className="ct-py7">
						<Dropdown className="ct-dropdown">
							<Dropdown.Toggle variant="success" id="dropdown-basic" className="ct-btndropdown">
								<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="18" cy="18" r="18" fill="#FF9E2B" />
									<path d="M26.0407 24.5607C24.8508 22.5037 23.0172 21.0287 20.8774 20.3295C21.9358 19.6994 22.7582 18.7392 23.2182 17.5965C23.6781 16.4538 23.7503 15.1917 23.4235 14.004C23.0968 12.8163 22.3892 11.7687 21.4094 11.0221C20.4296 10.2755 19.2318 9.87109 18 9.87109C16.7682 9.87109 15.5704 10.2755 14.5907 11.0221C13.6109 11.7687 12.9033 12.8163 12.5765 14.004C12.2498 15.1917 12.3219 16.4538 12.7819 17.5965C13.2419 18.7392 14.0642 19.6994 15.1227 20.3295C12.9828 21.0279 11.1492 22.5029 9.9594 24.5607C9.91577 24.6319 9.88683 24.711 9.87429 24.7935C9.86174 24.8761 9.86585 24.9602 9.88638 25.0411C9.9069 25.122 9.94341 25.198 9.99377 25.2646C10.0441 25.3311 10.1073 25.3869 10.1796 25.4287C10.2519 25.4704 10.3318 25.4973 10.4146 25.5076C10.4974 25.518 10.5814 25.5117 10.6618 25.489C10.7421 25.4664 10.8171 25.4279 10.8823 25.3758C10.9475 25.3237 11.0016 25.2591 11.0414 25.1857C12.5133 22.642 15.1149 21.1232 18 21.1232C20.8852 21.1232 23.4867 22.642 24.9586 25.1857C24.9984 25.2591 25.0526 25.3237 25.1178 25.3758C25.183 25.4279 25.258 25.4664 25.3383 25.489C25.4186 25.5117 25.5027 25.518 25.5855 25.5076C25.6683 25.4973 25.7482 25.4704 25.8205 25.4287C25.8927 25.3869 25.9559 25.3311 26.0063 25.2646C26.0566 25.198 26.0932 25.122 26.1137 25.0411C26.1342 24.9602 26.1383 24.8761 26.1258 24.7935C26.1132 24.711 26.0843 24.6319 26.0407 24.5607ZM13.625 15.4982C13.625 14.6329 13.8816 13.7871 14.3623 13.0676C14.8431 12.3481 15.5264 11.7874 16.3258 11.4562C17.1252 11.1251 18.0049 11.0385 18.8535 11.2073C19.7022 11.3761 20.4818 11.7928 21.0936 12.4046C21.7055 13.0165 22.1222 13.796 22.291 14.6447C22.4598 15.4934 22.3731 16.373 22.042 17.1725C21.7109 17.9719 21.1501 18.6552 20.4306 19.1359C19.7112 19.6166 18.8653 19.8732 18 19.8732C16.8401 19.872 15.728 19.4106 14.9078 18.5904C14.0876 17.7702 13.6263 16.6582 13.625 15.4982Z" fill="white" />
								</svg>
							</Dropdown.Toggle>
							<Dropdown.Menu className="ct-dropdownmenu">
								{parsedData?.data?.user ?
									<>
										<Dropdown.Item onClick={() => navigate("/profile")}>Profile</Dropdown.Item>
										<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
									</>
									:
									<Dropdown.Item onClick={() => navigate("/login")}>Login</Dropdown.Item>
								}
							</Dropdown.Menu>
						</Dropdown>
					</li> */}
					<li>
						<Proposal />
					</li>
				</ul>
			</div>
			<div className="ct-navigation">
				<nav className="ct-nav">
					<ul>
						<li>
							<Link to="/about">About us</Link>
						</li>
						<li>
							<Link to="/people">People</Link>
						</li>
						<li>
							<Link to="/capabilities">Capabilities</Link>
						</li>
						{/* <li>
							<Link to="/careers">Careers</Link>
						</li> */}
						<li>
							<Link to="/insights">Insights</Link>
						</li>
						<li>
							<Link to="/recruiter">Recruiter</Link>
						</li>
						<li>
							<Link to="/staffing">Staffing</Link>
						</li>
						<li>
							<Link to="/how-it-works">How it works</Link>
						</li>
						<li>
							<Link to="/referral">Referral Program</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	)
}