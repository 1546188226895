import React from "react";
import reactDom from "react-dom";

export default function Tool(props){
    return(
        <div className="ct-tool">
            <figure className="ct-toolicon">
                <img src={props.tool.icon} />
            </figure>
            <div className="ct-tooltitle">
                <h3>{props.tool.title}</h3>
            </div>
            <div className="ct-description">
                <p>{props.tool.description}</p>
            </div>
        </div>
    );
};