import { Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import api from "../../../axiosInstance";

const NavigationButtons = ({ step, setStep, setApplyAs, setActive, formik, jobId, data, stage, applyAs, userId, refetch, file, setFile, workExperienceFormik, refetchJOB }) => {
    const applyJobApiCalling = async (data) => {
        return await api.post("/applicants", data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const { mutate, isLoading } = useMutation(applyJobApiCalling, {
        onSuccess: () => {
            toast.success("applied successfully!")
            setStep(0)
            setApplyAs("")
            setActive("false")
            setFile("")
            formik.resetForm()
            workExperienceFormik.resetForm()
        },
        onError: (data) => {
            if (data.response.status === 403) {
                toast.error("token expired try to login again")
            }
            toast.error(data.response.data.message)
        }
    });

    const quickapplyJobApiCalling = async (data) => {
        return await api.post(`/applicants/quick-apply/${userId}/${jobId}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const { mutate: quickApply, isLoading: isQuickApplying } = useMutation(quickapplyJobApiCalling, {
        onSuccess: () => {
            refetchJOB && refetchJOB()
            toast.success("applied successfully!")
            refetch && refetch()
            setStep(0)
            setApplyAs("")
            setActive("false")
            setFile("")
        },
        onError: (data) => {
            if (data.response.status === 403) {
                toast.error("token expired try to login again")
            }
            toast.error(data.response.data.message)
        }
    });

    // console.log(data);

    const handleGuest = () => {
        const formData = new FormData()
        formData.append("file", data.basicDetail.pdfURL)
        formData.append("data", JSON.stringify({ ...data, isGuest: true, jobId, stage }))
        mutate(formData)
    }

    const handleQuickApply = () => {
        const formData = new FormData()
        if (typeof data.basicDetail.pdfURL !== "string") {
            formData.append("file", data.basicDetail.pdfURL)
        }
        formData.append("data", JSON.stringify({ ...data, stage }))
        quickApply(formData)
    }

    return (
        <div className="ct-btnholder">
            <button className="ct-btn ct-btncancel"
                onClick={() => {
                    setStep(0)
                    setApplyAs("")
                    setActive("false")
                }}
            >Discard</button>
            {/* <button className="ct-btn ct-btncancel">Save Draft</button> */}
            <button className="ct-btn" disabled={step === 0} onClick={() => { setStep(step - 1) }}>Previous</button>
            {step === 3 ?
                <button disabled={isLoading || isQuickApplying} className="ct-btn" onClick={(e) => {
                    e.preventDefault()
                    // const formData = new FormData()
                    applyAs === "quick" ?
                        handleQuickApply()
                        :
                        handleGuest()
                    // formData("file", file)
                    // formData("data",JSON.stringify*)
                    // mutate({ ...data, isGuest: true, jobId, stage })
                }}>{isLoading || isQuickApplying ? <Spinner size="sm" /> : "Finish"}</button>
                :
                <button className="ct-btn" type="submit" disabled={step === 3 || !formik.isValid}>Next</button>
            }
        </div>
    );
};

export default NavigationButtons