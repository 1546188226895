import React, {useEffect} from "react";
import reactDom from "react-dom";
import Header from "../header/Header";
import Banner_image from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import About_Us from "./About_Us";
import About_Us_One from "./About_Us_One";
import Services from "../main/services/Services"
import Work_Clients from "../main/work-clients/Work_Clients";
import Stats from "../main/stats/Stats";
import Get_In_Touch from "../contact/Get_In_Touch";
// import Perks from "../main/perks/Perks";
// import All_Jobs from "../main/all_jobs/All_Jobs";
// import Insight from "../main/insight/Insight";
// import Cooperates from "../main/cooperate/Cooperates";
import Accordion from 'react-bootstrap/Accordion';
import AOS from "aos";
import "aos/dist/aos.css";

export default function About(){
    useEffect(() => {
        AOS.init(100000);
    }, []);
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/about-banner.png")],
                title: 'Delivering value that businesses deserve Since 2009',
                description: 'We are driven by change and motivated by achievements. Consforc is one of the most trusted & reliable technology partners, and next destination for many startups.',
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-aboutus">
                    <div className="ct-cooperatesholder">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-aboutusgrid">
                                    <div data-aos="fade-left">
                                        <div className="ct-sectiontitle">
                                            <h2>About Consforc</h2>
                                        </div>
                                    </div>
                                    <div data-aos="fade-down">
                                        <h3>Founders’ Philosophy</h3>
                                        <p>Our founders have been serving clients in North America and Europe based on the philosophy of helping clients save locally, expand globally – most of our clients have leveraged our offshore experts for improved ROI and exponentially grow their businesses.  </p>
                                    </div>
                                    <div data-aos="fade-right">
                                        <h3>Our Purpose & Value System</h3>
                                        <p>Over the last decade, we have constantly aligned our efforts with the vision of market leaders and trend setters. Our ultimate purpose is to serve the needs of others as our own, and continue to stand by them even after the goals are achieved.</p>
                                    </div>
                                    <div data-aos="fade-left">
                                        <h3>Value System & Best practices</h3>
                                        <p>Our corporate values are deeply ingrained in our DNA -People come first. We have a strong conviction to help people thrive in their industries and enable them to emerge on top in whatever they do. We employ the latest tools and techniques and make Read more</p>
                                    </div>
                                    <div data-aos="fade-up">
                                        <h3>History</h3>
                                        <p>Reliable partner in Technology, IT staffing, management consulting and back-office operations since 2008. We represent a wide range of industries catering unique use cases.</p>
                                    </div>
                                    <div data-aos="fade-right">
                                        <h3>Team Structure</h3>
                                        <p>Consforc teams are dedicated, talented, and above all extremely reliable. Our vertical and horizontal internal reporting structure empowers delivery leads to ensure faster completion of projects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <About_Us_One />
                    <About_Us />
                    <div className="ct-visionholder">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-visiongrid">
                                    <div className="ct-vision" data-aos="fade-up-left">
                                        <div className="ct-sectionhead">
                                            <div className="ct-sectiontitle">
                                                <h2>Vision</h2>
                                            </div>
                                            <div className="ct-description">
                                                <p>We always aim to supersede delivery expectations while being competitive and Agile. We have become the most reliable partner for many companies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ct-vision" data-aos="fade-up-right">
                                        <div className="ct-sectionhead">
                                            <div className="ct-sectiontitle">
                                                <h2>Availability</h2>
                                            </div>
                                            <div className="ct-description">
                                                <p><span>24/7/365</span> Our SLA empowers our customers to comfortably expand in the market while offering their services in diverse industries. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Services /> */}
                    <Work_Clients />
                    {/* <Stats /> */}
                    <div className="ct-certifaction">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-sectionhead text-center" data-aos="fade-up">
                                    <div className="ct-sectiontitle">
                                        <h2>Tools & Certifications</h2>
                                    </div>
                                </div>
                                <div className="ct-toolscontent" data-aos="fade-down">
                                    <div className="ct-tools">
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/1.jpg" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/2.jpg" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/3.jpg" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/4.jpg" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/5.jpg" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/6.png" alt="tools image" />
                                            </figure>
                                        </div>
                                        <div className="ct-tool">
                                            <figure>
                                                <img src="image/about/tools-certifications/7.png" alt="tools image" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ct-build">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-sectionhead">
                                    <div className="ct-sectiontitle" data-aos="fade-down-left">
                                        <h2>Build world-class partnerships with us</h2>
                                    </div>
                                    <div className="ct-description" data-aos="fade-down-right">
                                        <p>Our team is trained both internationally and locally, so they understand your needs and the environment in which you do business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ct-aboutcapabilites">
                        <div className="ct-haslayout ct-sectionspace">
                            <div className="ct-containerfluid">
                                <div className="ct-sectionhead text-center">
                                    <div className="ct-sectiontitle" data-aos="fade-left">
                                        <h2>Capabilities</h2>
                                    </div>
                                </div>
                                <div className="ct-aboutcapabilitiesholder" data-aos="fade-right">
                                    <div>
                                        <img src="/image/about/capabilities/1.svg" />
                                        <h4>Financial Services</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/2.svg" />
                                        <h4>SaaS Development </h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/3.svg" />
                                        <h4>Application Development</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/4.svg" />
                                        <h4>Project Management</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/5.svg" />
                                        <h4>Back-office Operations</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/6.svg" />
                                        <h4>Cloud</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/7.svg" />
                                        <h4>Security & Compliance</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/8.svg" />
                                        <h4>Quality Control</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/9.svg" />
                                        <h4>User Journey</h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/10.svg" />
                                        <h4>Data Analytics & Reporting </h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/11.svg" />
                                        <h4>Virtual Teams </h4>
                                    </div>
                                    <div>
                                        <img src="/image/about/capabilities/12.svg" />
                                        <h4>Client Support</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ct-ourlocationholder ct-stafingsuccessstory">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-sectionhead text-center">
                                    <div className="ct-sectiontitle" data-aos="fade-down">
                                        <h2>Our success stories</h2>
                                    </div>
                                </div>
                                <div className="ct-ourlocationcontentholder">
                                    <div className="ct-ourlocations">
                                        <div className="ct-ourlocation" data-aos="fade-left">
                                            <figure>
                                                <img src="image/1.png" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3>Success stories 1</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <Link>Read more</Link>
                                            </div>
                                        </div>
                                        <div className="ct-ourlocation" data-aos="fade-up">
                                            <figure>
                                                <img src="image/2.png" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3> Success stories 2 </h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <Link>Read more</Link>
                                            </div>
                                        </div>
                                        <div className="ct-ourlocation" data-aos="fade-right">
                                            <figure>
                                                <img src="image/3.png" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3>Success stories 3</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <Link>Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <All_Jobs /> */}
                    <Get_In_Touch />
                </div>
            </main>
            <Footer />
        </>
    )
}