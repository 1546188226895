import React, {useEffect} from "react";
import reactDom from "react-dom";
import "./work.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Work_Clients(){
    useEffect(() => {
        AOS.init(12000);
    }, []);
    return(
        <div className="ct-workclients">
            <div className="ct-haslayout ct-sectionspace">
                {/* <div className="ct-container"> */}
                    <div className="ct-workwithclientholder">
                        <div className="ct-workclientsimg" data-aos="fade-right">
                            <figure>
                                <img src=" image/whyus.png" />
                            </figure>
                        </div>
                        <div className="ct-workclientcontentholder" data-aos="fade-left">
                            <div className="ct-sectionhead">
                                <div className="ct-sectiontitle">
                                    <h2>Why Us </h2>
                                </div>
                                <div className="ct-description">
                                    <p>Our approach to take on new challenges has been fine-tuned over time to produce desired results. With years of industry experience and using the latest tools & techniques, we have learned to solve the most complex problems in the simplest of ways.</p>
                                </div>
                            </div>
                            <div className="ct-whyusgridholder">
                                <h3>What sets us apart</h3>
                                <div className="ct-whyusgrid">
                                    <div className="ct-griditem">
                                        <figure>
                                            <img src="/image/about/why-us/1.svg" />
                                        </figure>
                                        <h4>Professional <span>Services </span> </h4>
                                    </div>
                                    <div className="ct-griditem">
                                        <figure>
                                            <img src="/image/about/why-us/2.svg" />
                                        </figure>
                                        <h4>Large workforce <span>community  </span> </h4>
                                    </div>
                                    <div className="ct-griditem">
                                        <figure>
                                            <img src="/image/about/why-us/3.svg" />
                                        </figure>
                                        <h4>Expandable <span>resources </span> </h4>
                                    </div>
                                    <div className="ct-griditem">
                                        <figure>
                                            <img src="/image/about/why-us/4.svg" />
                                        </figure>
                                        <h4>Industry Standard <span>Certifications </span> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}