import React, {useEffect} from "react";
import reactDom from "react-dom";
import Capabilitiestabs from "./Capabilitiestabs";
import "./capabilities.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Capabilities(){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-capabilitiesholder">
            <div className="ct-haslayout ct-sectionspace">
                <div className="ct-container">
                    <div className="ct-sectionhead text-center"  data-aos="fade-down">
                        <div className="ct-sectiontitle">
                            <h2>Capabilities</h2>
                        </div>
                        <div className="ct-description">
                            <p>Do more while focusing on what matters for your expansion and stability</p>
                        </div>
                    </div>
                    <div className="ct-capabilitiestabsarea"  id="ct-rotatesection">
                        <Capabilitiestabs />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}