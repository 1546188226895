import React, {useEffect} from "react";
import reactDom from "react-dom";
import Header from "../header/Header";
import Banner_image from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import Our_Location from "./Our_Location";
import Get_In_Touch_two from "./Get_In_Touch_Two";
import "./contact.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact(){
    useEffect(() => {
        AOS.init(2000);
      }, []); 
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/contact.png")] ,
                title: 'Contact us',
                description: 'Talk with an expert today',
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-contactus">
                    {/* <Our_Location /> */}
                    <div className="ct-ourlocationholder">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-sectionhead text-center" data-aos="fade-down">
                                    <div className="ct-sectiontitle">
                                        <h2>Our Location</h2>
                                    </div>
                                </div>
                                <div className="ct-ourlocationcontentholder">
                                    <figure className="ct-worldmap">
                                        <img src="image/world-map.png" />
                                    </figure>
                                    {/* <div className="ct-ourlocations">
                                        <div className="ct-ourlocation" data-aos="fade-left">
                                            <figure>
                                                <img src="image/loc2.jpeg" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3>Chicago</h3>
                                                <p>Abc Building, 12 St, Washington D.C, USA</p>
                                                <span>+1 234 565 4256</span>
                                                <Link>Get Directions</Link>
                                            </div>
                                        </div>
                                        <div className="ct-ourlocation" data-aos="fade-up">
                                            <figure>
                                                <img src="image/loc3.jpeg" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3> Boston</h3>
                                                <p>Abc Building, 12 St, Washington D.C, USA</p>
                                                <span>+1 234 565 4256</span>
                                                <Link>Get Directions</Link>
                                            </div>
                                        </div>
                                        <div className="ct-ourlocation" data-aos="fade-right">
                                            <figure>
                                                <img src="image/loc1.jpeg" />
                                            </figure>
                                            <div className="ct-ourlocationdetail">
                                                <h3>Lahore</h3>
                                                <p>Abc Building, 12 St, Washington D.C, USA</p>
                                                <span>+1 234 565 4256</span>
                                                <Link>Get Directions</Link>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ct-contentdetailsholder">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-container">
                                <div className="ct-contactdetailgrid">
                                    <div className="ct-contactcard" data-aos="fade-left">
                                        <ul>
                                            <li>
                                                <i className="icon-envelop"></i>
                                            </li>
                                            <li>
                                                <h3>Email us</h3>
                                            </li>
                                            <li>
                                                <p>Email us for general queries, including marketing and partnership opportunities.</p>
                                            </li>
                                            <li>
                                                <a href="mailto:info@consforc.com">Info@consforc.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ct-contactcard" data-aos="fade-up">
                                        <ul>
                                            <li>
                                            
                                                <i className="icon-Icon_Call"></i>
                                            </li>
                                            <li>
                                                <h3>Call us</h3>
                                            </li>
                                            <li>
                                                <p>Call us to speak to a member of our team. We are always happy to help.</p>
                                            </li>
                                            <li>
                                                <a href="tel:">123456789</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ct-contactcard" data-aos="fade-right">
                                        <ul>
                                            <li>
                                                <i className="icon-chevron-down-11"></i>
                                            </li>
                                            <li>
                                                <h3>Support</h3>
                                            </li>
                                            <li>
                                                <p>Check out helpful resources, FAQs and developer tools.</p>
                                            </li>
                                            <li>
                                                <a href="mailto:support@consforc.com.pk">Support@consforc.com.pk</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <Get_In_Touch_two />
                </div>
            </main>
            <Footer />
        </>
    )
}