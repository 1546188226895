export function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map((c) => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(""),
    );

    return JSON.parse(jsonPayload);
}

export function objectToQueryString(filters) {
    const queryString = Object.entries(filters)
        .map(([key, values]) => {
            if (Array.isArray(values) && values.length > 0) {
                return `${key}=${values.join(',')}`;
            }
            return '';
        })
        .filter(query => query !== '')
        .join('&');

    return queryString;
}