import React from "react";
import { Link } from "react-router-dom";
import './sidebar.css';
export default function Sidebar (){
    const openSideBarHandler = () => {
        const el = document.getElementById('root');
        if(el){
            el.classList.toggle("showSideBar");
        }   
    }
    return(
        <aside className="ct-sidebarwrapper">
            <div className="ct-sidebar">
                <div className="ct-sidebarsocialarea">
                    {/* <button className="ct-btnmenue" type="button" onClick={openSideBarHandler}> <i className="icon-th-menu"></i> </button> */}
                    <button className="ct-btnmenu" onClick={openSideBarHandler}>
                        <i className="icon-menu1"></i>
                    </button>
                    <ul className="ct-socialicons">
                        {/* <li>
                            <a href="" target="_blank">
                                <i className="icon-facebook1"></i>
                            </a>
                        </li> */}
                        <li>
                            <a href="https://www.linkedin.com/company/consforc/" target="_blank">
                                <i className="icon-linkedin2"></i>
                            </a>
                        </li>
                        {/* <li>
                            <a href="javascript: void(0);">
                                <i className="icon-Twitter"></i>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div className="ct-sidebarcontent">
                    <div className="ct-sidebarcategorie">
                        <strong className="ct-log" onClick={openSideBarHandler}>
                            <a href="/">
                                <img src="image/flogo.svg" />
                                {/* <img src="image/adlogo.png" alt=""/> */}
                            </a>
                        </strong>
                        <div className="ct-navigation">
                            <nav className="ct-nav" onClick={openSideBarHandler}>
                                <ul>
                                    <li>
                                        <Link to="/capabilities">Capabilities </Link>
                                        <div className="ct-subcategories d-none">
                                            <ul>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Financial Services</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Back-office Operations </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>User Journey</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>SaaS Development</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Cloud</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>IT Infrastructure</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Application Development</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Security & Compliance</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Virtual Teams</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Project Management</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Quality Control</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/capabilities">
                                                        <span>Client Support</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <Link to="/careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/insights">Insights</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/about">About us</Link>
                                    </li>
                                    <li>
                                        <Link to="/people">People</Link>
                                    </li>
                                    <li>
                                        <Link to="/insights">Insights</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/insights">Insights</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/recruiter">Recruiter</Link>
                                    </li>
                                    <li>
                                        <Link to="/staffing">Staffing</Link>
                                    </li>
                                    <li>
                                        <Link to="/how-it-works">How It Works</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact us</Link>
                                    </li>
                                    {/* <li>
                                        <a href="/">Email Subscription</a>
                                    </li> */}
                                </ul>
                                {/* <ul>
                                    <li>
                                        <a href="javascript: void(0);">Sign in</a>
                                    </li>
                                    <li>
                                        <a href="javascript: void(0);">Sign up</a>
                                    </li>
                                </ul> */}
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="ct-sidebarsubcategories">
                    {/* <div className="ct-searchform">
                        <form>
                            <fieldset>
                                <div className="form-group ct-inputwidthicon">
                                    <input type="text" placeholder="Search Here"/>
                                    <i className="icon-search"></i>
                                </div>
                            </fieldset>
                        </form>
                    </div> */}
                    <div className="ct-subitemcategoriecholder">
                        <div className="ct-subcategorieholder">
                            <div className="ct-subcategorietitle">
                                <h2>Capabilities</h2>
                            </div>
                            <div className="ct-subcategories">
                                <ul onClick={openSideBarHandler}>
                                    <li>
                                        <a href="/capabilities">
                                            <i className=" icon-chevron-down"></i>
                                            <span>Financial Services</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-4"></i>
                                            <span>Back-office Operations </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-Icon_CS"></i>
                                            <span>Design UI/UX</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-1"></i>
                                            <span>SaaS Development</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-5"></i>
                                            <span>Cloud</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-9"></i>
                                            <span>Data Analytics & Reporting</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-2"></i>
                                            <span>Application Development</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-6"></i>
                                            <span>Security & Compliance</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-10"></i>
                                            <span>Virtual Teams</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-3"></i>
                                            <span>Project Management</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-7"></i>
                                            <span>Quality Control</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/capabilities">
                                            <i className="icon-chevron-down-11"></i>
                                            <span>Client Support</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}