import React, { useEffect, useState } from "react";
// import reactDom from "react-dom";
import Header from "../header/Header";
import BannerImage from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
// import Jobs from "../main/jobs/jobs";
// import Get_In_Touch from "../contact/Get_In_Touch";
// import Perks from "../main/perks/Perks";
// import { Link } from "react-router-dom";
// import Filter from "../main/filter/Filter";
// import Short_By from "../main/filter/Short_By";
import AOS from "aos";
import "aos/dist/aos.css";
import JobCard from "../main/jobs/job";
import JobFilter from "../main/filter/Filter";
import { useQuery } from "react-query";
import { objectToQueryString } from "../../utilities/common";
import { Spinner } from "react-bootstrap";
// import Delete_Modal from "../../user-profile/Delete_Modal";
import api from "../../axiosInstance";

export default function Careers() {
    const [filter, setFilter] = useState({})

    const filterSetter = (key, value, e) => {
        e && e.preventDefault()
        if (filter[key] !== undefined && filter[key].includes(value)) {
            const newVal = filter[key].filter((el) => el !== value)
            setFilter((prevState) => ({ ...prevState, [key]: newVal }))
        } else if (filter[key] !== undefined && !filter[key].includes(value)) {
            const arr = [...filter[key]]
            arr.push(value)
            setFilter((prevState) => ({ ...prevState, [key]: arr }))
        } else {
            setFilter((prevState) => ({ ...prevState, [key]: [value] }))
        }
    }

    const getJobsListApi = async (data) => {
        const filtersQuey = objectToQueryString(data.queryKey[2])
        return await api.get(`/admin/job/get-jobs/${data.queryKey[1]}?${filtersQuey}`)
    }
    const { data, isLoading, refetch } = useQuery(
        ["get-jobs-list", "opened", filter],
        getJobsListApi,
        {
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        AOS.init(1000);
    }, []);
    return (
        <>
            <Header />
            
            <BannerImage banner={{
                image: [("image/job-apply/careerbanner.png")],
                title: 'Careers at Consforc ',
                description: "Consectetur adipisicing elit, sed do eiusmod tempor incididunt utnaitas labore etolore magna aliqua minim veniam quis nostrud exercitation ullamco laboris aliquip commodo consequat aute irure."
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-container">
                    <div className="ct-tabscreatbtn py-5">
                        {/* <Delete_Modal/> */}
                        <div className="ct-jobstwocol">
                            <div className="ct-jobcolone">
                                <JobFilter filterSetter={filterSetter} idValue="opened" />
                            </div>
                            {isLoading ? <div style={{ textAlign: "center", paddingTop: 15 }}><Spinner /> </div> :
                                <div className="ct-jobcoltwo">
                                    <span className="ct-totalcount">Showing <span>48</span> job openings</span>
                                    <div className="ct-jobs">
                                        {data?.data?.jobs.length ? data.data.jobs.map((el, index) => (
                                            <JobCard data={el} key={index} refetch={refetch} />
                                        )) : <div style={{ textAlign: "center", paddingTop: 15 }}>No jobs to show</div>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}