import React from "react";
import reactDom from "react-dom";

export default function Capabilitie_box(props){
    return(
        <div className="ct-capabilitieservise">
            <figure className="ct-capabiliteimg">
                <img src={props.capabiliti.img} alt="capabilitie image" />
            </figure>
            <div className="ct-title">
                <h3>{props.capabiliti.title}</h3>
            </div>
            <div className="ct-description">
                <p>{props.capabiliti.description}</p>
            </div>
        </div>
    )
}