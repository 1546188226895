// import react from 'react';
import React, {useEffect} from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import './refer.css'
import "react-multi-carousel/lib/styles.css";
import Button from 'react-bootstrap/Button';
import emailjs from "@emailjs/browser";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef, useState  } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';

export default function Refer(){ 
  const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  const form = useRef();
  const [selectValue, setSelectValue] = useState("");
  const sendEmail = (e) => {
    console.log(e);
    e.preventDefault();
    console.log(selectValue);
    emailjs.sendForm("service_qhgc558", "template_qzoh13j", form.current, "nxvN8hBaUNLCIL73Y").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
  };
  useEffect(() => {
    AOS.init(2000);
  }, []); 

    return(
        <>
            <Header/>
            <div className="ct-bannerwithoutslide ct-banner">
            <figure>
                <img src="image/referb.png"  />
            </figure>
            <div className="ct-bannertitlearea">
            <div className="ct-bannertitle ct-bannertitlevtwo">
              <h1>Refer and earn $200 <br />it is that simple.</h1>
            </div>
            <div className="ct-bannerdescription">
              <p>Bring talent to the spotlight, and get rewarded for your efforts after they successfully complete 90 days.</p>
            </div>
            <ul>
              <li>
                <a href="#ct-referform" className="ct-btn">Start referring </a>                
              </li>
              <li>
                <a href="https://www.linkedin.com/company/consforc/posts/" target="_blank" className="ct-btngetstarted">
                  <span>See open roles</span>
                  <i className="icon-arrow-right2"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
            <Sidebar />
            <main className="ct-main">
              <div className='ct-refer'>
                <section className="ct-haslayout ct-sectionspace ct-benifatspartner">
                  <div className="ct-containerfluid">
                    <div className="ct-benifatescontentholder">
                      <div className="ct-sectionhead" data-aos="fade-left">
                        <div className="ct-sectiontitle">
                          <h2>Refer friends, family or anyone you know and help others thrive!</h2>
                        </div>
                        <div className="ct-description">
                          <p>You can refer whoever you want as long as they align with our open opportunities, you can help people become a part of our growth journey and add value to our superior remote work culture and experienced teams that transcend all borders and boundaries.</p>
                        </div>
                      </div>
                      <figure className="ct-benifatimageholder rt-referseconeimg"  data-aos="fade-right">
                        <img src="image/refer1.png" />
                      </figure>
                    </div>
                  </div>
                </section>
                <div className="ct-toolsholder">
                  <div className="ct-sectionspace ct-haslayout">
                      <div className="ct-containerfluid">
                          <div className="ct-sectionhead text-center"  data-aos="down">
                              <div className="ct-sectiontitle">
                                  <h2>How it works</h2>
                              </div>
                          </div>
                          <div className="ct-toolscontent">
                              <div className="ct-tools ct-referhowitwork" data-aos="fade-left">
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/1.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Browse open jobs</h3>
                                    </div>
                                </div>
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/2.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Refer Top Talent</h3>
                                    </div>
                                </div>
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/3.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Get rewarded!</h3>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="ct-haslayout text-center">
                            <a href="https://www.linkedin.com/company/consforc/posts/" target="_blank"  className="ct-btn ct-btnbggreen">See open roles</a>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="ct-toolsholder">
                  <div className="ct-sectionspace ct-haslayout">
                      <div className="ct-containerfluid">
                          <div className="ct-sectionhead text-center"  data-aos="down">
                              <div className="ct-sectiontitle">
                                  <h2>Value of our referral program</h2>
                              </div>
                          </div>
                          <div className="ct-toolscontent">
                              <div className="ct-tools ct-referandhelp" data-aos="fade-left">
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/01.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Help friends & family</h3>
                                    </div>
                                </div>
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/02.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Foster a stronger professional community</h3>
                                    </div>
                                </div>
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/03.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Help people grow</h3>
                                    </div>
                                </div>
                                <div className="ct-tool">
                                    <figure className="ct-toolicon">
                                        <img src="image/refer/04.png"/>
                                    </figure>
                                    <div className="ct-tooltitle">
                                        <h3>Get a reward</h3>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <section id="ct-referform" className="ct-haslayout ct-sectionspace ct-referform">
                  <div className="ct-containerfluid">
                    <div className="ct-formcontentholder">
                      <div className="ct-referformarea">
                        <div className="ct-formthemeholder">
                          <form className="ct-formtheme"  ref={form} onSubmit={sendEmail} >
                            <fieldset>
                              <div className="ct-modaltitle">
                                <div>
                                  <h2>Your details</h2>
                                </div>
                              </div>
                                <div className="form-group">
                                  <label>Full name</label>
                                  <input type="text" name="fname" />
                                </div>
                                <div className="form-group">
                                  <label>Email</label>
                                  <input type="text" name="email" />
                                </div>
                                <div className="form-group">
                                  <label>Phone</label>
                                  <input type="text" name="phone" />
                                </div>
                                <div className="ct-modaltitle">
                                  <div>
                                    <h2>Referral’s details</h2>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Referral’s full name</label>
                                  <input type="text" name="rfname" />
                                </div>
                                <div className="form-group">
                                  <label>Referral’s email address</label>
                                  <input type="text" name="remail" />
                                </div>
                                <div className="form-group">
                                  <label>LinkedIn Profile URL</label>
                                  <input type="text" name="linked" />
                                </div>
                                <div className="form-group">
                                  <label>Comments </label>
                                  <textarea className="form-control" name="comments" placeholder="Please provide the details of position, skillset, and any other information about the referral."></textarea>
                                  {/* <input type="text" name="Comments" /> */}
                                </div>
                                <div className="ct-btntermscondation">
                                  <p>
                                    By submitting a referral, you agree to the <a href="javascript: void(0);" onClick={handleShow}>Terms & Conditions</a> for our referral program
                                  </p>
                                  <Modal show={show} onHide={handleClose}>
                                    <div className="ct-termscontent">
                                      <h3>Terms & Conditions: Referral Program</h3>
                                      <ol>
                                        <li>
                                          <p>If the referral is already in Consforc's recruitment process pipeline, your referral will not be considered.</p>
                                        </li>
                                        <li>
                                          <p>Referrals are considered on first come first served basis. In case two or more people submit the same profile, only one of them will be considered and the person to submit it first will be considered for referral reward.</p>
                                        </li>
                                        <li>
                                          <p>To become eligible for referral reward, the referral must successfully complete 90 (Ninety) days of employment with Consforc.</p>
                                        </li>
                                        <li>
                                          <p>There is no limit on referring candidates.</p>
                                        </li>
                                        <li>
                                          <p>Referrals are only accepted for open roles.</p>
                                        </li>
                                        <li>
                                          <p>Referral will not be considered if the eligible candidate referral was already made by an internal employee or external individual in the last 6 (Six) months or if the referral was in the recruitment pipeline during the last 180 (One hundred eighty) days from the date of referral.</p>
                                        </li>
                                        <li>
                                          <p>Referral award will be paid to the eligible referrer within 45 (forty five) days of the completion of 3 (three) months of continuous employment with Consforc in the bank account provided by the eligible referrer.</p>
                                        </li>
                                        <li>
                                          <p>After the referral award is paid to the referrer, no other claims of payment shall be made to Consforc by the eligible referrer.</p>
                                        </li>
                                        <li>
                                          <p>Eligible referrers who cannot receive the referral award in their own bank account shall refrain from participating in this program.</p>
                                        </li>
                                        <li>
                                          <p>Eligible referrers who participate in this program without meeting this requirement shall not have the right to claim any amounts to Consforc.</p>
                                        </li>
                                      </ol>
                                    </div>
                                    <div className="ct-btnholder">
                                        <Button className="ct-btn ct-btnbggreen" variant="" onClick={handleClose}>
                                        Cancel
                                        </Button>
                                    </div>
                                  </Modal>
                                </div>
                              <div className="ct-btnholder">
                                  <Button className="ct-btn ct-btnbggreen" type="submit" variant="">
                                      Submit
                                  </Button>
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="ct-referformattachmentarea">
                        <div className="ct-formemailattachment">
                          <figure>
                            <img src="image/refer/email.png" />
                          </figure>
                          <div className="ct-attachmenttitle">
                            <h2>
                              Or email us their resume on 
                              <span>referral.candidate@consforc.com</span>
                            </h2>
                            <a href="https://www.linkedin.com/company/consforc/posts/" target="_blank" className="ct-btngetstarted">
                              <span>See open roles</span>
                              <i className="icon-arrow-right2"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ct-btntermscondation">
                      <p>
                        By submitting a referral, you agree to the <a href="javascript: void(0);" onClick={handleShow}>Terms & Conditions</a> for our referral program
                      </p>
                      <Modal show={show} onHide={handleClose}>
                        <div className="ct-termscontent">
                          <h3>Terms & Conditions: Referral Program</h3>
                          <ol>
                            <li>
                              <p>If the referral is already in Consforc's recruitment process pipeline, your referral will not be considered.</p>
                            </li>
                            <li>
                              <p>Referrals are considered on first come first served basis. In case two or more people submit the same profile, only one of them will be considered and the person to submit it first will be considered for referral reward.</p>
                            </li>
                            <li>
                              <p>To become eligible for referral reward, the referral must successfully complete 90 (Ninety) days of employment with Consforc.</p>
                            </li>
                            <li>
                              <p>There is no limit on referring candidates.</p>
                            </li>
                            <li>
                              <p>Referrals are only accepted for open roles.</p>
                            </li>
                            <li>
                              <p>Referral will not be considered if the eligible candidate referral was already made by an internal employee or external individual in the last 6 (Six) months or if the referral was in the recruitment pipeline during the last 180 (One hundred eighty) days from the date of referral.</p>
                            </li>
                            <li>
                              <p>Referral award will be paid to the eligible referrer within 45 (forty five) days of the completion of 3 (three) months of continuous employment with Consforc in the bank account provided by the eligible referrer.</p>
                            </li>
                            <li>
                              <p>After the referral award is paid to the referrer, no other claims of payment shall be made to Consforc by the eligible referrer.</p>
                            </li>
                            <li>
                              <p>Eligible referrers who cannot receive the referral award in their own bank account shall refrain from participating in this program.</p>
                            </li>
                            <li>
                              <p>Eligible referrers who participate in this program without meeting this requirement shall not have the right to claim any amounts to Consforc.</p>
                            </li>
                          </ol>
                        </div>
                        <div className="ct-btnholder">
                            <Button className="ct-btn ct-btnbggreen" variant="" onClick={handleClose}>
                            Cancel
                            </Button>
                        </div>
                      </Modal>
                    </div> */}
                  </div>
                </section>
                <section className="ct-haslayout ct-sectionspace ct-referform">
                  <div className="ct-containerfluid">
                    <div className="ct-sectionhead text-center"  data-aos="down">
                      <div className="ct-sectiontitle">
                        <h2>Frequently Asked Questions</h2>
                      </div>
                    </div>
                    <div className="ct-faqscontentholder">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>How many people can I refer in this program?</Accordion.Header>
                        <Accordion.Body>
                          There is no limit on the number of candidates a referrer can submit for consideration.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>When will I get paid for the referral?</Accordion.Header>
                        <Accordion.Body>
                          You will be paid after the candidate successfully completes 90 days of employment.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>How will I get paid for the referral?</Accordion.Header>
                        <Accordion.Body>
                          The amount will be paid to you in your bank account only.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Why is my referral not accepted?</Accordion.Header>
                        <Accordion.Body>
                          Referrals are not accepted for a number of reasons including any referrals that are already in our recruitment process, have been referred before by internal or external eligible candidates in the last 180 days, or have been employed by the company in the last 180 days will not be considered as eligible referrals.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>How will I know my referral is hired?</Accordion.Header>
                        <Accordion.Body>
                          You will receive an email notification from us congratulating you for earning the referral award.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    </div>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
        </>
    )
}