import { Country, State, City } from 'country-state-city';

const CascadingDropdowns = ({ formik }) => {
    const countries = Country.getAllCountries();
    const states = formik.values.country ? State.getStatesOfCountry(JSON.parse(formik.values.country).code) : [];
    const cities = formik.values.state ? City.getCitiesOfState(JSON.parse(formik.values.country).code, JSON.parse(formik.values.state).code) : [];

    return (
        <>
        <div className="ct-inputwidthfifty">
            <div className='form-group'>
                <label>
                    Country:
                </label>
                    <select {...formik.getFieldProps("country")}>
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                            <option key={country.isoCode} value={JSON.stringify({ name: country.name, code: country.isoCode })}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                {formik.touched.country && formik.errors.country && (
                    <div className="invalid-feedback-custom">{formik.errors.country}</div>
                )}
                </div>
                    
                <div className='form-group'>
                    <label>
                        State:
                    </label>
                    <select {...formik.getFieldProps("state")}>
                        <option value="">Select State</option>
                        {states.map((state) => (
                            <option key={state.isoCode} value={JSON.stringify({ name: state.name, code: state.isoCode })}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                    {formik.touched.state && formik.errors.state && (
                        <div className="invalid-feedback-custom">{formik.errors.state}</div>
                    )}
                </div>
            </div>
            <div className='form-group'>
                    
                <label>
                    City:
                </label>
                <select {...formik.getFieldProps("city")}>
                    <option value="">Select City</option>
                    {cities.map((city) => (
                        <option key={city.name} value={city.name}>
                            {city.name}
                        </option>
                    ))}
                </select>
                {formik.touched.city && formik.errors.city && (
                    <div className="invalid-feedback-custom">{formik.errors.city}</div>
                )}
            </div>
        </>
    );
};

export default CascadingDropdowns;
