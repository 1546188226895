import React from "react";
import reactDom from "react-dom";
import Insight from "../main/insight/Insight";
import Header from "../header/Header";
import Banner_image from "../banner/Banner_image";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Get_In_Touch from "../contact/Get_In_Touch";

export default function Insights(){
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/insight.png")] ,
                title: 'Solving Problems Is In Our DNA',
                description: 'Extraordinary challenges require extraordinary solutions. Employing our result-oriented approach, we are always prepared to deliver solutions that are both cost and time efficient for our clients.   ',
            }} />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-aboutus">
                    <Insight />
                    <Get_In_Touch />
                </div>
            </main>
            <Footer />
        </>
    )
}