// import react from 'react';
import React, {useEffect} from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Banner_image from "../banner/Banner_image";
import Get_In_Touch from "../contact/Get_In_Touch";
// import './partner.css';
import './How.css'
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function How_It_Works(){ 
  useEffect(() => {
    AOS.init(2000);
  }, []); 
    return(
        <>
            <Header/>
            <Banner_image banner={{
                image: [("image/howitwork.png")] ,
                title: 'Process: Exceptionally refined & FAST! ',
            }} />
            <Sidebar />
            <main className="ct-main">
              <div className='ct-recuriter'>
                <section className="ct-howitworkfirst ct-haslayout ct-sectionspace">
                  <div className="ct-container">
                    <div className="ct-promisedgrid">
                      <div data-aos="fade-left">
                        <h3>Promised, Delivered </h3>
                        <p>Side by side every step of the way. </p>
                      </div>
                      <div data-aos="fade-left">
                        <h3>Experience</h3>
                        <p>Leverage industry experience to achieve quality results</p>
                      </div>
                      <div data-aos="fade-up">
                        <h3>Passion</h3>
                        <p>Disrupt old trends to create more efficient pathways.</p>
                      </div>
                      <div data-aos="fade-down">
                        <h3>Drive</h3>
                        <p>Dedication to achieve the impossible</p>
                      </div>
                      <div data-aos="fade-right">
                        <h3>Teamwork</h3>
                        <p>Carve the way with most ingenious </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className='ct-haslayout ct-sectionspace'>
                  <div className='ct-container'>
                    <div className='ct-sectionhead text-center'  data-aos="fade-down">
                      <div className='ct-sectiontitle'>
                        <h2>Fulfilling Global Commitment </h2>
                      </div>
                      <div className='ct-descrition'>
                        <p>Through years and years of continuous delivery, we have refined our ways of doing things to get where we want, from where we are. We value our simple, yet powerful client onboarding process specifically tailored to diverse business needs and produce results that make a difference.</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className='ct-confidance ct-haslayout ct-sectionspace'>
                  <div className='ct-container'>
                    <div className='ct-recuriterholder'>
                      <div className='ct-sectionhead'  data-aos="fade-left">
                        <div className='ct-sectiontitle'>
                          <h2>Projects </h2>
                        </div>
                        <div className='ct-descrition'>
                          <p>Based on your business needs, you can choose to outsource your technology projects to our expert teams and get things done with minimum to no supervision at all. Our Agile development teams will ensure a timely delivery of your projects while keeping the scope in check and our project delivery team will keep you up to date on progress - utilizing incremental development approach, our teams will allow you to go to market even before your planned actual launch date while maintaining the quality of your product at the forefront of every phase.</p>
                        </div>
                      </div>
                      <div className='ct-recuritercontent'  data-aos="fade-right">
                        <ul>
                          <li>  
                            <span>01</span>
                            <p>Meet with our technical team for project evaluation.</p>
                          </li>
                          <li>
                            <span>02</span>
                            <p>Development starts after your consent on scope.</p>
                          </li>
                          <li>
                            <span>03</span>
                            <p>Go to market with your product.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <section className='ct-confidance ct-haslayout ct-sectionspace'>
                  <div className='ct-container'>
                    <div className='ct-recuriterholder'>
                      <div className='ct-recuritercontent float-left' data-aos="fade-left">
                        <ul>
                          <li>  
                            <span>01</span>
                            <p><a href="javascript: void(0);">Ready:</a> Submit your resource requirements.</p>
                          </li>
                          <li>
                            <span>02</span>
                            <p><a href="javascript: void(0);">Set:</a> Finalize subscription & workforce schedules. </p>
                          </li>
                          <li>
                            <span>03</span>
                            <p><a href="javascript: void(0);">Go: </a>Manage teams as they drive  your business forward</p>
                          </li>
                        </ul>
                      </div>
                      <div className='ct-sectionhead float-right' data-aos="fade-right">
                        <div className='ct-sectiontitle'>
                          <h2>Team Augmentation </h2>
                        </div>
                        <div className='ct-descrition'>
                          <p>Extend your team’s agility, capacity, and technical knowledge by adding competitive resources who will enable your business to take flight whether you wish to run an on-shore or off-shore Back-office or develop technologically advance and superior systems – our assigned resources will become a remote extension of your core teams and stay on top of the action items. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* <div className="ct-ourlocationholder ct-stafingsuccessstory">
                    <div className="ct-sectionspace ct-haslayout">
                        <div className="ct-container">
                            <div className="ct-sectionhead text-center"  data-aos="fade-down">
                                <div className="ct-sectiontitle">
                                    <h2>Our success stories</h2>
                                </div>
                            </div>
                            <div className="ct-ourlocationcontentholder">
                                <div className="ct-ourlocations">
                                    <div className="ct-ourlocation"  data-aos="fade-left">
                                        <figure>
                                            <img src="image/1.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 1</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-up">
                                        <figure>
                                            <img src="image/2.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3> Success stories 2 </h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/3.png" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Success stories 3</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <Link>Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Get_In_Touch />
              </div>
            </main>
            <Footer />
        </>
    )
}