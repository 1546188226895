/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import reactDom from "react-dom";
import './footer.css';
import CopyRight from './CopyRight';
import NewsLetter from './NewsLetter';
import { Link } from "react-router-dom";
export default function Footer(){
    return(
        <footer className="ct-footer">
            <div className="ct-containerfluid">
                <div className="ct-footercontent">
                    <div className="ct-footerlogoarea">
                        <strong className="ct-log">
                            <a href="javascript: void(0);">
                                {/* <img src="image/adlogo.png" alt=""/> */}
                                <img src="image/flogo.svg" alt="footer logo" />
                            </a>
                        </strong>
                        <div className="ct-description">
                            <p>Consforc is focused on helping industry leaders materialize their goals by providing cutting edge technology and augmentation solutions.</p>
                        </div>
                        <div className="ct-socialicons">
                            <ul>
                                {/* <li>
                                    <a href="" target="_blank">
                                        <img src="/image/s3.svg" />
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a href="">
                                        <img src="/image/s2.svg" />
                                    </a>
                                </li> */}
                                <li>
                                    <a href="https://www.linkedin.com/company/consforc/" target="_blank">
                                        <img src="/image/s1.svg" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="ct-footercontactus">
                        <h5>Feel Free To Contact Us</h5>
                        <ul>
                            <li>
                                <a href="mailto:info@consforc.com">
                                    <span>E:</span>
                                    <span>info@consforc.com</span>
                                </a>
                            </li>
                            <li>
                                <a href="tel:847 802 9741">
                                    <span>T:</span>
                                    <span>847 802 9741</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="ct-usefullink">
                        <h5>Useful Links</h5>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                                <Link to="/recruiter">Recruiter</Link>
                            </li>
                            <li>
                                <Link to="/about">About us</Link>
                                <Link to="/staffing">Staffing</Link>
                            </li>
                            {/* <li>
                                <Link to="/insights">Insights</Link>
                                <Link to="/careers">Careers</Link>
                            </li> */}
                            <li>
                                <Link to="/capabilities">Capabilities</Link>
                                <Link to="/contact-us">Contact us</Link>
                            </li>
                            <li>
                                <Link to="/how-it-works">How it works</Link>
                                <Link to="/referral">Referral Program</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <NewsLetter /> */}
            <CopyRight />
        </footer>
    )
}