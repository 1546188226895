import React, {useEffect} from "react";
import reactDom from "react-dom";
import Tabs from 'react-bootstrap/Tabs';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Tab from 'react-bootstrap/Tab';
import './coverge.css';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Coverge(){
    useEffect(() => {
        AOS.init(12000);
      }, []);
    return(
        <div className="ct-covergeholder">
            <div className="ct-haslayout ct-sectionspace">
                <div className="ct-containerfluid">
                    <div className="ct-covergeheadingarea">
                        <div className="ct-sectionhead"  data-aos="fade-down">
                            <div className="ct-sectiontitle">
                                <h2  data-aos="fade-right">Coverage</h2>
                            </div>
                            <div className="ct-description">
                                <p  data-aos="fade-left">A wide range of industries that we represent and counting</p>
                            </div>
                        </div>
                        <div className="ct-covergetabs"  data-aos="fade-up-right">
                            <Tabs defaultActiveKey="Financial" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="Financial" title="Financial Industry">
                                    <figure  data-aos="fade-left">
                                        <img src="image/coverage/7.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="it" title="IT Industry">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/2.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Consumer" title="Consumer products">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/3.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Retail" title="Retail">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/1.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Services" title="Healthcare Systems & Services">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/4.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="logistics" title="Travel & logistics">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/5.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Real" title="Real Estate">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/6.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Artificial" title="Artificial Intelligence">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/8.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="Blockchain" title="Blockchain">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/9.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="E-commerce" title="E-commerce">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/10.jpg" />
                                    </figure>
                                </Tab>
                                <Tab eventKey="IoT" title="IoT">
                                    <figure data-aos="fade-left">
                                        <img src="image/coverage/11.jpg" />
                                    </figure>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}