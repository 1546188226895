import React, {useEffect} from "react";
import ReactDOM from "react";
import Insight_Card from "./Insight_Card";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AOS from "aos";
import "aos/dist/aos.css";

export default function Insight(){
    useEffect(() => {
        AOS.init(1000);
    }, []);    
    return(
        <div className="ct-insightholder">
            <div className="ct-sectionspace ct-haslayout">
                <div className="ct-container">
                    <div className="ct-sectionhead text-center">
                        <div className="ct-sectiontitle">
                            <h2>Insights</h2>
                        </div>
                        <div className="ct-description">
                            <p>Extraordinary challenges require extraordinary solutions. Employing our result-oriented approach, we are always prepared to deliver solutions that are both cost and time efficient for your company.</p>
                        </div>
                    </div>
                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                        <div className="ct-sectionspace ct-haslayout">
                            <div className="ct-ourlocationcontentholder">
                                    <div className="ct-ourlocations">
                                    <div className="ct-ourlocation" data-aos="fade-left">
                                        <figure>
                                            <img src="image/insights/01.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Real estate</h3>
                                            <p>Property portfolio management is made easy by creating a multi-tenant platform for property managers to help them effortlessly manage lease documents, monthly payments, bookkeeping, and maintenance.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-down">
                                        <figure>
                                            <img src="image/insights/02.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3> FinTech</h3>
                                            <p> Implementation of B2B Middleware solutions to launch card programs with the click of a button. </p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/03.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Logistics</h3>
                                            <p>Geo-fencing to control and monitor delivery vehicles to reduce delivery overhead costs.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/04.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Healthcare</h3>
                                            <p>Designed and implemented a patient management system to track patients from incident to cure.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/05.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>E-commerce</h3>
                                            <p> Multi-vendor solutions to quickly onboard private sellers and go international.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/06.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>SaaS Product Development</h3>
                                            <p>Used cutting-edge technology to develop subscription-oriented products that helped achieve a complete digital transformation.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/07.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Cloud Infrastructure</h3>
                                            <p>Delivered Multi-cloud solutions by leveraging the power of different cloud services to enable platforms to deliver the actual feature set and realize potential.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/08.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Data Analytics</h3>
                                            <p>Set up data lakes to power the KPIs used by industry leaders in decision-making and maximizing revenue growth.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/09.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Quality Control</h3>
                                            <p>Automated the process of performing software quality checks which reduced errors by 99.99%.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/010.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>AI/ML</h3>
                                            <p>Designed and trained AI models for accurate translations, predictions, and recommendations. </p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/011.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Application development</h3>
                                            <p>Redesigned applications for process improvements, efficiency, and usability.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/012.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Project Management</h3>
                                            <p>Implemented PMO best practices, policies and procedures from the ground up to manage high-value programs and portfolios.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                    <div className="ct-ourlocation" data-aos="fade-right">
                                        <figure>
                                            <img src="image/insights/013.jpg" />
                                        </figure>
                                        <div className="ct-ourlocationdetail">
                                            <h3>Back office</h3>
                                            <p>Instilled exceptional processes and personnel to run back office operations supporting customers in different time zones.</p>
                                            {/* <a href="javascript:void(0);">Read more</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className=" ct-insighttabs">
                        <Tab eventKey="all" title="all">
                            <Tabs defaultActiveKey="Sales" id="uncontrolled-tab-example" className=" ct-insightfilter">
                                <Tab eventKey="Sales" title="Financial Services">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                 <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation" data-aos="fade-left">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation" data-aos="fade-down">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation" data-aos="fade-right">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </Tab>
                                <Tab eventKey="Marketing" title="Project Management">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Lead" title="Talent Management">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="strategy" title="Application Development">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Company" title="Back-Office support">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab eventKey="articles" title="articles">
                        <Tabs defaultActiveKey="Marketing" id="uncontrolled-tab-example" className=" ct-insightfilter">
                                <Tab eventKey="Sales" title="Financial Services">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Marketing" title="Project Management">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Lead" title="Lead Generation">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="strategy" title="Application Development">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Company" title="Back-Office support">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab eventKey="tutorials" title="tutorials">
                        <Tabs defaultActiveKey="Marketing" id="uncontrolled-tab-example" className=" ct-insightfilter">
                                <Tab eventKey="Sales" title="Financial Services">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Marketing" title="Project Management">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Lead" title="Lead Generation">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="strategy" title="Application Development">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Company" title="Back-Office support">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab eventKey="resources" title="resources">
                        <Tabs defaultActiveKey="Marketing" id="uncontrolled-tab-example" className=" ct-insightfilter">
                                <Tab eventKey="Sales" title="Financial Services">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Marketing" title="Project Management">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Lead" title="Lead Generation">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="strategy" title="Application Development">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Company" title="Back-Office support">
                                    <div className="ct-ourlocationholder ct-stafingsuccessstory">
                                        <div className="ct-sectionspace ct-haslayout">
                                            <div className="ct-ourlocationcontentholder">
                                                <div className="ct-ourlocations">
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/1.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 1</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/2.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3> Success stories 2 </h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                    <div className="ct-ourlocation">
                                                        <figure>
                                                            <img src="image/3.png" />
                                                        </figure>
                                                        <div className="ct-ourlocationdetail">
                                                            <h3>Success stories 3</h3>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                            <a href="javascript:void(0);">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                    </Tabs> */}
                </div>
            </div>
        </div>
    )
}