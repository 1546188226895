import * as Yup from 'yup';

export const referralSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    r_phoneNumber: Yup.string().required('Phone number is required'),
    r_firstName: Yup.string().required('First name is required'),
    r_lastName: Yup.string().required('Last name is required'),
    r_email: Yup.string().email('Invalid email address').required('Email is required'),
    linkedinURL: Yup.string().url('Invalid LinkedIn URL'),
    pdf: Yup.mixed().required("PDF is required"),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('city is required'),
    pdfURL: Yup.mixed().required('Resuma is required'),
    experienceLevel: Yup.string().required('Experience level is required'),
});