import React, { Component }  from 'react';
import reactDom from "react-dom";
import { Link } from "react-router-dom";
// import { PopupWidget } from 'react-calendly/typings';
import "./banner.css";


export default function Banner_image(props){
  
    return(
        <div className="ct-bannerwithoutslide ct-banner">
            <figure>
                <img src={props.banner.image}  />
            </figure>
            <div className="ct-bannertitlearea">
            <div className="ct-bannertitle">
              <h1>{props.banner.title}</h1>
            </div>
            <div className="ct-bannerdescription">
              <p>{props.banner.description}</p>
            </div>
            <ul>
              <li>
                <a href="https://calendly.com/consforcllc/company-overview"className="ct-btn" target="_blank">Book a Meeting </a>
              </li>
              <li>
                <a href="/contact-us" className="ct-btngetstarted">
                  <span>Get Started</span>
                  <i className="icon-arrow-right2"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
    )
}