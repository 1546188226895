import React from "react";
import reactDom from "react-dom";
export default function BannerCategorie(props){
    return(
        <li>
            <div className="ct-bannercategorititle">
                <h3>{props.categorie.title}</h3>
            </div>
            <div className="ct-bannercategoridescription">
                <p>{props.categorie.description}</p>
            </div>
        </li>
    )
}