import { React, memo } from "react";
import CascadingDropdowns from "./coutry-state-selector";
import { isString } from "formik";

const BasicDetails = memo(({ formik, navigation }) => {
    // console.log(typeof formik.values.pdfURL);
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="ct-applyjobform">
                <div className="ct-jobpreviewscroll">
                    <div className="ct-formtheme" >
                        <fieldset>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>First name</label>
                                    <input type="text" {...formik.getFieldProps("firstName")} />
                                    {formik.touched.firstName && formik.errors.firstName && (
                                        <div className="invalid-feedback-custom">{formik.errors.firstName}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Last name </label>
                                    <input type="text" {...formik.getFieldProps("lastName")} />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                        <div className="invalid-feedback-custom">{formik.errors.lastName}</div>
                                    )}
                                </div>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" {...formik.getFieldProps("email")} />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="invalid-feedback-custom">{formik.errors.email}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Phone number</label>
                                    <input type="text" {...formik.getFieldProps("phoneNumber")} />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                        <div className="invalid-feedback-custom">{formik.errors.phoneNumber}</div>
                                    )}
                                </div>
                            </div>
                            <div className="ct-inputwidthfifty">
                                <div className="form-group">
                                    <label>Experience level</label>
                                    <select {...formik.getFieldProps("experienceLevel")}>
                                        <option>--- select ---</option>
                                        <option>Entry-level</option>
                                        <option>Junior (1-2 years)</option>
                                        <option>Mid (2-5 years)</option>
                                        <option>Senior (5-10 years)</option>
                                        <option>Expert (15+ years)</option>
                                    </select>
                                    {formik.touched.experienceLevel && formik.errors.experienceLevel && (
                                        <div className="invalid-feedback-custom">{formik.errors.experienceLevel}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>LinkedIn profile URL (optional)</label>
                                    <input type="text" {...formik.getFieldProps("linkedinURL")} />
                                </div>
                            </div>
                            {/* <div className="form-group"> */}
                                <CascadingDropdowns formik={formik} />
                            {/* </div> */}
                            <div className="form-group">
                                <label>Profile Summary</label>
                                <textarea placeholder="0/100 words" {...formik.getFieldProps("profileSummary")}></textarea>
                                {formik.touched.profileSummary && formik.errors.profileSummary && (
                                    <div className="invalid-feedback-custom">{formik.errors.profileSummary}</div>
                                )}
                            </div>
                            
                            <div className="ct-uploadfile">
                                <input type="file" id="file" accept="application/pdf" onChange={(e) => formik.setFieldValue('pdfURL', e.target.files[0])} />
                                <label htmlFor="file">
                                    <span className="ct-fileicon">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_618_18588)">
                                                <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                                <path d="M33.3844 12.8656L24.6344 4.11563C24.5182 3.99958 24.3803 3.90756 24.2286 3.84483C24.0768 3.78209 23.9142 3.74987 23.75 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V33.75C6.25 34.413 6.51339 35.0489 6.98223 35.5178C7.45107 35.9866 8.08696 36.25 8.75 36.25H31.25C31.913 36.25 32.5489 35.9866 33.0178 35.5178C33.4866 35.0489 33.75 34.413 33.75 33.75V13.75C33.7501 13.5858 33.7179 13.4232 33.6552 13.2714C33.5924 13.1197 33.5004 12.9818 33.3844 12.8656ZM25 8.01719L29.4828 12.5H25V8.01719ZM31.25 33.75H8.75V6.25H22.5V13.75C22.5 14.0815 22.6317 14.3995 22.8661 14.6339C23.1005 14.8683 23.4185 15 23.75 15H31.25V33.75ZM24.6344 21.6156C24.7505 21.7318 24.8426 21.8696 24.9055 22.0214C24.9683 22.1731 25.0007 22.3358 25.0007 22.5C25.0007 22.6642 24.9683 22.8269 24.9055 22.9786C24.8426 23.1304 24.7505 23.2682 24.6344 23.3844C24.5182 23.5005 24.3804 23.5926 24.2286 23.6555C24.0769 23.7183 23.9142 23.7507 23.75 23.7507C23.5858 23.7507 23.4231 23.7183 23.2714 23.6555C23.1196 23.5926 22.9818 23.5005 22.8656 23.3844L21.25 21.7672V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.7672L17.1344 23.3844C17.0182 23.5005 16.8804 23.5926 16.7286 23.6555C16.5769 23.7183 16.4142 23.7507 16.25 23.7507C16.0858 23.7507 15.9231 23.7183 15.7714 23.6555C15.6196 23.5926 15.4818 23.5005 15.3656 23.3844C15.2495 23.2682 15.1574 23.1304 15.0945 22.9786C15.0317 22.8269 14.9993 22.6642 14.9993 22.5C14.9993 22.3358 15.0317 22.1731 15.0945 22.0214C15.1574 21.8696 15.2495 21.7318 15.3656 21.6156L19.1156 17.8656C19.2317 17.7494 19.3696 17.6572 19.5213 17.5943C19.6731 17.5314 19.8357 17.499 20 17.499C20.1643 17.499 20.3269 17.5314 20.4787 17.5943C20.6304 17.6572 20.7683 17.7494 20.8844 17.8656L24.6344 21.6156Z" fill="#00C981" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_618_18588">
                                                    <rect width="40" height="40" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    {typeof formik.values.pdfURL !== "string" ?
                                        <h3>{formik.values.pdfURL?.name}</h3> :
                                        <h3>Click to <span>upload</span> resumes</h3>}
                                    <p>(supported format: .pdf)</p>
                                </label>
                                {isString(formik.values.pdfURL) && formik.values.pdfURL ?
                                    <div className="ct-uploadedresumes pt-1 pb-4">
                                        <ul>
                                            <li>
                                                <div style={{ display: "flex" }}>
                                                    <em>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.6922 6.43281L12.3172 2.05781C12.2591 1.99979 12.1902 1.95378 12.1143 1.92241C12.0384 1.89105 11.9571 1.87494 11.875 1.875H4.375C4.04348 1.875 3.72554 2.0067 3.49112 2.24112C3.2567 2.47554 3.125 2.79348 3.125 3.125V16.875C3.125 17.2065 3.2567 17.5245 3.49112 17.7589C3.72554 17.9933 4.04348 18.125 4.375 18.125H15.625C15.9565 18.125 16.2745 17.9933 16.5089 17.7589C16.7433 17.5245 16.875 17.2065 16.875 16.875V6.875C16.8751 6.7929 16.859 6.71159 16.8276 6.63572C16.7962 6.55985 16.7502 6.4909 16.6922 6.43281ZM11.875 6.875V3.4375L15.3125 6.875H11.875Z" fill="#00C981" />
                                                        </svg>
                                                    </em>
                                                    <h4 className="mb-0">{formik.values.pdfURL.split("/")[3]}</h4>
                                                    {/* <span>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM18 19.5H6V6H18V19.5ZM7.5 2.25C7.5 2.05109 7.57902 1.86032 7.71967 1.71967C7.86032 1.57902 8.05109 1.5 8.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25C16.5 2.44891 16.421 2.63968 16.2803 2.78033C16.1397 2.92098 15.9489 3 15.75 3H8.25C8.05109 3 7.86032 2.92098 7.71967 2.78033C7.57902 2.63968 7.5 2.44891 7.5 2.25Z" fill="#E03E3E" />
                                                                            </svg>
                                                                        </span> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div> : null}
                                {formik.touched.pdfURL && formik.errors.pdfURL && (
                                    <div className="invalid-feedback-custom">{formik.errors.pdfURL}</div>
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            {navigation}
        </form>
    )
})

export default BasicDetails