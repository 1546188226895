import React from "react";
import { TagsInput } from "react-tag-input-component";

export default function WorkEducation({ formik, navigation }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="ct-applyjobform">
                <div className="ct-jobpreviewscroll">
                    <div className="ct-formtheme">
                        <fieldset>
                            <div className="ct-initialquestionwidget">
                                {formik.values.educations.map((education, index) => (
                                    <div key={index}>
                                        <div className="form-group mb-2" >
                                            <label>Education</label>
                                            <input type="text"
                                                placeholder="Institute name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={education.institute}
                                                name={`educations[${index}].institute`}
                                            />
                                            {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].institute && (
                                                <div className="invalid-feedback-custom">{formik.errors.educations[index].institute}</div>
                                            )}
                                        </div>
                                        <div className="ct-selectplusinput mb-2">
                                            <div className="ct-selectarea">
                                                <select className="at-selecttheme"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    defaultValue={education.degree.level}
                                                    name={`educations[${index}].degree.level`}
                                                >
                                                    <option>Degree level</option>
                                                    <option>Bachelors</option>
                                                    <option>Masters</option>
                                                </select>
                                                <div className="ct-inputwithicon">
                                                    <input type="text" placeholder="e.g are you willing to relocate?"
                                                        name={`educations[${index}].degree.title`}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={education.degree.title}
                                                    />
                                                    <i>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM18 19.5H6V6H18V19.5ZM7.5 2.25C7.5 2.05109 7.57902 1.86032 7.71967 1.71967C7.86032 1.57902 8.05109 1.5 8.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25C16.5 2.44891 16.421 2.63968 16.2803 2.78033C16.1397 2.92098 15.9489 3 15.75 3H8.25C8.05109 3 7.86032 2.92098 7.71967 2.78033C7.57902 2.63968 7.5 2.44891 7.5 2.25Z" fill="#E03E3E" />
                                                        </svg>
                                                    </i>
                                                </div>
                                                {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].degree && formik.errors.educations[index].degree.title && (
                                                    <div className="invalid-feedback-custom">{formik.errors.educations[index].degree.title}</div>
                                                )}
                                                {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].degree && formik.errors.educations[index].degree.level && (
                                                    <div className="invalid-feedback-custom">{formik.errors.educations[index].degree.level}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="ct-inputwidthfifty">
                                            <div className="form-group">
                                                <input type="date"
                                                    name={`educations[${index}].startDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={education.startDate}
                                                />
                                                {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].startDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.educations[index].startDate}</div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <input type="date"
                                                    name={`educations[${index}].endDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={education.endDate}
                                                />
                                                {formik.touched.educations && formik.errors.educations && formik.errors.educations[index] && formik.errors.educations[index].endDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.educations[index].endDate}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="form-group ct-btnaddoption">
                                    <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("educations", [...formik.values.educations, { institute: "", startDate: "", endDate: "", degree: { title: "", level: "" } }])}>
                                        <em>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                            </svg>
                                        </em>
                                        <span>Add option</span>
                                    </button>
                                </div>
                            </div>
                            <div className="ct-initialquestionwidget">
                                {formik.values.certifications.map((certification, index) => (
                                    <div key={index}>
                                        <div className="form-group mb-2">
                                            <label>Certification</label>
                                            <input type="text" placeholder="Issued By"
                                                name={`certifications[${index}].issuedBy`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={certification.issuedBy}
                                            />
                                            {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].issuedBy && (
                                                <div className="invalid-feedback-custom">{formik.errors.certifications[index].issuedBy}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-2">
                                            <input type="text" placeholder="Institute name"
                                                name={`certifications[${index}].institute`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={certification.institute}
                                            />
                                            {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].institute && (
                                                <div className="invalid-feedback-custom">{formik.errors.certifications[index].institute}</div>
                                            )}
                                        </div>
                                        <div className="ct-inputwidthfifty">
                                            <div className="form-group">
                                                <input type="date"
                                                    name={`certifications[${index}].startDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={certification.startDate}
                                                />
                                                {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].startDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.certifications[index].startDate}</div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <input type="date"
                                                    name={`certifications[${index}].endDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={certification.endDate}
                                                />
                                                {formik.touched.certifications && formik.errors.certifications && formik.errors.certifications[index] && formik.errors.certifications[index].endDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.certifications[index].endDate}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="form-group ct-btnaddoption">
                                    <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("certifications", [...formik.values.certifications, { institute: "", startDate: "", endDate: "" }])}>
                                        <em>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                            </svg>
                                        </em>
                                        <span>Add option</span>
                                    </button>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label>Tools & Technologies</label>
                                <TagsInput
                                    value={formik.values.tools}
                                    onChange={(tags) => formik.setFieldValue("tools", tags)}
                                    name="tools"
                                    onBlur={formik.handleBlur}
                                    placeHolder="press Enter to add"
                                />
                                {formik.touched.tools && formik.errors.tools && (
                                    <div className="invalid-feedback-custom">{formik.errors.tools}</div>
                                )}
                            </div>
                            <div className="ct-initialquestionwidget">
                                {formik.values.experiences.map((experience, index) => (
                                    <div key={index}>
                                        <div className="form-group mb-2">
                                            <label>Work Experience</label>
                                            <input type="text" placeholder="Job role"
                                                name={`experiences[${index}].jobRole`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={experience.jobRole}
                                            />
                                            {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].jobRole && (
                                                <div className="invalid-feedback-custom">{formik.errors.experiences[index].jobRole}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-2">
                                            <input type="text" placeholder="Company name"
                                                name={`experiences[${index}].company`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={experience.company}
                                            />
                                            {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].company && (
                                                <div className="invalid-feedback-custom">{formik.errors.experiences[index].company}</div>
                                            )}
                                        </div>
                                        <div className="ct-inputwidthfifty">
                                            <div className="form-group">
                                                <input type="date" placeholder="Start date"
                                                    name={`experiences[${index}].startDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={experience.startDate}
                                                />
                                                {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].startDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.experiences[index].startDate}</div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <input type="date" placeholder="End date"
                                                    name={`experiences[${index}].endDate`}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={experience.endDate}
                                                />
                                                {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].endDate && (
                                                    <div className="invalid-feedback-custom">{formik.errors.experiences[index].endDate}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <textarea placeholder="Details"
                                                name={`experiences[${index}].details`}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={experience.details}
                                            ></textarea>
                                            {formik.touched.experiences && formik.errors.experiences && formik.errors.experiences[index] && formik.errors.experiences[index].details && (
                                                <div className="invalid-feedback-custom">{formik.errors.experiences[index].details}</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                <div className="form-group ct-btnaddoption">
                                    <button type="button" className="ct-btn border-0 p-0 ct-btncancel" onClick={() => formik.setFieldValue("experiences", [...formik.values.experiences, { jobRole: "", company: "", startDate: "", endDate: "", details: "" }])}>
                                        <em>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.5 8C15.5 8.16576 15.4342 8.32473 15.3169 8.44194C15.1997 8.55915 15.0408 8.625 14.875 8.625H8.625V14.875C8.625 15.0408 8.55915 15.1997 8.44194 15.3169C8.32473 15.4342 8.16576 15.5 8 15.5C7.83424 15.5 7.67527 15.4342 7.55806 15.3169C7.44085 15.1997 7.375 15.0408 7.375 14.875V8.625H1.125C0.95924 8.625 0.800269 8.55915 0.683058 8.44194C0.565848 8.32473 0.5 8.16576 0.5 8C0.5 7.83424 0.565848 7.67527 0.683058 7.55806C0.800269 7.44085 0.95924 7.375 1.125 7.375H7.375V1.125C7.375 0.95924 7.44085 0.800269 7.55806 0.683058C7.67527 0.565848 7.83424 0.5 8 0.5C8.16576 0.5 8.32473 0.565848 8.44194 0.683058C8.55915 0.800269 8.625 0.95924 8.625 1.125V7.375H14.875C15.0408 7.375 15.1997 7.44085 15.3169 7.55806C15.4342 7.67527 15.5 7.83424 15.5 8Z" fill="#00C981" />
                                            </svg>
                                        </em>
                                        <span>Add option</span>
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            {navigation}
        </form>
    )
}