import React from "react";
import reactDom from "react-dom";
import "./filter.css";


export default function Short_By(){
    return(
        <div className="ct-shortbyholder">
            <form className="ct-formtheme">
                <fieldset>
                    <div className="ct-container">
                        <div className="ct-shortbycontent">
                            <ul>
                                <li>
                                    <div className="form-group ct-inputwithicon">
                                        <label>Short By</label>
                                        <div className="ct-select">
                                            <select>
                                                <option>Relevance</option>
                                                <option>Relevance</option>
                                                <option>Relevance</option>
                                                <option>Relevance</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group ct-inputwithicon">
                                        <label>Type</label>
                                        <div className="ct-select">
                                            <select>
                                                <option>Full Time</option>
                                                <option>Full Time</option>
                                                <option>Full Time</option>
                                                <option>Full Time</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group ct-inputwithicon">
                                        <label>Lavel</label>
                                        <div className="ct-select">
                                            <select>
                                                <option>Senior</option>
                                                <option>Senior</option>
                                                <option>Senior</option>
                                                <option>Senior</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="ct-tooglewithclear">
                                        <div className="ct-togglebtn">
                                            <input type="checkbox" id="ct-toggle" />
                                            <label htmlFor="ct-toggle">
                                                <span>Remote Only</span>
                                            </label>
                                        </div>
                                        <div className="ct-btnclear">
                                            <button className="ct-clear">Clear</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}