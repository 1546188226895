import React, {useEffect} from "react";
import reactDom from "react-dom";
import Header from "../header/Header";
import Banner from "../banner/Banner";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Main from "../main/Main";
import Offer from '../main/offer/Offer';
import Tools from '../main/tools/Tools';
import Clients from "../main/clients/Clients";
import Coverge from "../main/coverge/Coverge";
import Join from "../main/join-team/Join";
import Benefits from "../main/benefits/Benefits";
import Cooperates from "../main/cooperate/Cooperates";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Capabilities from "../main/capabilities/Capabilities";
import AOS from "aos";
import "aos/dist/aos.css";
// import Drift from "react-driftjs";
import './home.css';

export default function Home(){
    useEffect(() => {
        AOS.init(12000);
    }, []);
    return(
        <>
            <Header/>
            <Banner />
            <Sidebar />
            <main className="ct-main">
                <div className="ct-marketingarea">
                    <Offer />
                </div>
                <div className="ct-capabilitiesholder">
                    <div className="ct-haslayout ct-sectionspace">
                        <div className="ct-containerfluid">
                            <div className="ct-agileholder">
                                <div className="ct-agileholdertabs">
                                    <Tabs defaultActiveKey="Agile" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="Agile" title="Agile Transformation">
                                            <p>Make your organization Agile oriented by adapting to latest scrum frameworks, industry trends, product development techniques, and marketing practices for improved speed to market and promise your customers quicker product releases.</p>
                                            <ul>
                                                <li>
                                                    <span>Higher ROI</span>
                                                </li>
                                                <li>
                                                    <span>Product Innovation</span>
                                                </li>
                                                <li>
                                                    <span>Latest technology integration</span>
                                                </li>
                                                <li>
                                                    <span>Organization improvements</span>
                                                </li>
                                            </ul>
                                        </Tab>
                                        <Tab eventKey="Growth" title="Growth Acceleration">
                                            <p>Consforc capabilities allow you to grow by scaling your business quickly using the best resources available globally.</p>
                                            <ul>
                                                <li>
                                                    <span>Talent representing over 25+ countries </span>
                                                </li>
                                                <li>
                                                    <span>Supporting all time-zones and regions</span>
                                                </li>
                                                <li>
                                                    <span>Option to select from preferred languages </span>
                                                </li>
                                            </ul>
                                        </Tab>
                                    </Tabs>
                                </div>
                                <figure>
                                    <img src="/image/agile.png" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <Capabilities />
                <Coverge />
                <Tools data-aos="fade-left" section={{
                    title: 'Tools & Technologies',
                    description: 'Compete the world with latest tech stacks .',
                }}>
                </Tools>
                <Cooperates />
                <Clients />
                <Benefits />
                {/* <Join /> */}
            </main>
            <Footer />
        </>
    )
}